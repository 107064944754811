import {
    $isListNode,
    ListItemNode
} from "@lexical/list";

export class ExtendedListItemNode extends ListItemNode {
    public static getType(): string {
        return "extended-list";
    }

    public static clone(node: ExtendedListItemNode): ExtendedListItemNode {
        return new ExtendedListItemNode(node.__value, node.__checked, node.__key);
    }

    private updateListItemStyle(dom) {
        const firstChild = dom.firstElementChild;
        if (firstChild && (firstChild.tagName === "UL" || firstChild.tagName === "OL")) {
            // eslint-disable-next-line no-param-reassign
            dom.style.listStyleType = "none";
        }
    }

    public createDOM(config) {
        const dom = super.createDOM(config);
        const children = this.getChildren();

        if (children.length > 0) {
            const firstChild = children[0];
            if ($isListNode(firstChild)) {
                dom.style.listStyleType = "none";
            }
        }

        this.updateListItemStyle(dom);
        return dom;
    }

    public updateDOM(prevNode, dom, config) {
        const updated = super.updateDOM(prevNode, dom, config);
        this.updateListItemStyle(dom);
        return updated;
    }
}
