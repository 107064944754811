
import "./Input.css";

import * as React from "react";

type Props = Readonly<{
  "data-test-id"?: string;
  accept?: string;
  label: string;
  onChange: (files: FileList | null) => void;
}>;

export default function FileInput({
    accept,
    label,
    onChange,
    "data-test-id": dataTestId
}: Props): JSX.Element {
    return (
        <div className="Input__wrapper">
            <label
                className="Input__label"
                htmlFor="editor-file-input"
            >
                {label}
            </label>
            <input
                accept={accept}
                className="Input__input"
                data-test-id={dataTestId}
                id="editor-file-input"
                type="file"
                onChange={(e) => onChange(e.target.files)}
            />
        </div>
    );
}
