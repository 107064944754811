import { Card } from "antd";
import * as React from "react";
import { FormattedMessage } from "react-intl";

import { ContentHeader } from "@components/contentHeader/contentHeader";
import { EditorField } from "@components/field/editorField";
import { Field } from "@components/field/field";
import { requiredrule } from "@components/form/requiredRule";
import { TranslationTabs } from "@components/translations/translationTabs/translationTabs";
import { languages } from "@pages/translationsPage/constants/languages";
import { LanguageErrors } from "@utils/errorsToLanguageErrors";
import { GalleryLanguageFieldProps } from "@utils/languageFieldProps";
import { maxCharacters } from "@utils/maxCharacters";
import { LanguageObject } from "@utils/toLanguageObject";
import { FormInstance } from "antd/lib/form";

export interface GalleryContentFormValues {
    title: LanguageObject;
    preview: LanguageObject;
    description: LanguageObject;
}

export interface GalleryContentCardProps {
    hidden?: boolean;
    defaultValues: Partial<GalleryContentFormValues>;
    disabled?: boolean;
    activeLanguage: string;
    languageErrors?: LanguageErrors;
    form: FormInstance;
    changeSelectedLanguage(languageCode: string): void;
    onBlur(): void;
}

const titleField = (props: GalleryLanguageFieldProps) => (
    <Field
        key={`title.${props.language}`}
        hidden={props.hidden}
        id={`title.${props.language}`}
        info={maxCharacters()}
        label={<FormattedMessage id="title" />}
        name={["title", props.language]}
        rules={[
            {
                max: 140,
                message: <FormattedMessage id="form.toolong" />
            },
            requiredrule
        ]}
        {...props.sharedProps}
    />
);

const previewField = (props: GalleryLanguageFieldProps) => (
    <Field
        key={`preview.${props.language}`}
        hidden={props.hidden}
        id={`preview.${props.language}`}
        label={<FormattedMessage id="preview" />}
        name={["preview", props.language]}
        rules={[requiredrule]}
        {...props.sharedProps}
    />
);

const descriptionField = (props: GalleryLanguageFieldProps) => (
    <EditorField
        key={`description.${props.language}`}
        disabled={props.sharedProps.disabled}
        hidden={props.hidden}
        id={`description.${props.language}`}
        isAdvancedMode={false}
        label={<FormattedMessage id="description" />}
        name={["description", props.language]}
        rules={[requiredrule]}
        value={props.defaultValues.description ? props.defaultValues.description[props.language] : ""}
        onChangeUnsaved={props.sharedProps.onBlur}
        {...props.sharedProps}
    />
);

export class GalleryContentCard extends React.Component<GalleryContentCardProps> {
    public shouldComponentUpdate(props) {
        return !(this.props.hidden && props.hidden);
    }

    public render() {
        const { changeSelectedLanguage, form, hidden, defaultValues, languageErrors, disabled, onBlur, activeLanguage } = this.props;
        const sharedProps = {
            disabled,
            form,
            onBlur
        };

        const langFieldProps = languages.map(language => ({ sharedProps, defaultValues, language: language.code, hidden: language.code !== activeLanguage }));

        return (
            <Card hidden={hidden}>
                <ContentHeader
                    title={<FormattedMessage id="gallery" />}
                />
                <TranslationTabs
                    activeLanguage={activeLanguage}
                    errors={languageErrors}
                    handleChangeSelectedLanguage={changeSelectedLanguage}
                    languages={languages}
                />
                {langFieldProps.map(titleField)}
                {langFieldProps.map(previewField)}
                {langFieldProps.map(descriptionField)}
            </Card>
        );
    }
}
