
import "./index.css";

import { $isCodeHighlightNode } from "@lexical/code";
import { $isLinkNode, TOGGLE_LINK_COMMAND } from "@lexical/link";
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { mergeRegister } from "@lexical/utils";
import {
    $getSelection,
    $isParagraphNode,
    $isRangeSelection,
    $isTextNode,
    COMMAND_PRIORITY_LOW,
    FORMAT_ELEMENT_COMMAND,
    FORMAT_TEXT_COMMAND,
    LexicalEditor,
    SELECTION_CHANGE_COMMAND
} from "lexical";
import * as React from "react";
import { Dispatch, useCallback, useEffect, useRef, useState } from "react";
import { createPortal } from "react-dom";

import { $isHeadingNode } from "@lexical/rich-text";
import { getDOMRangeRect } from "../../utils/getDOMRangeRect";
import { getSelectedNode } from "../../utils/getSelectedNode";
import { setFloatingElemPosition } from "../../utils/setFloatingElemPosition";
import { Divider } from "../ToolbarPlugin";

function TextFormatFloatingToolbar({
    editor,
    anchorElem,
    isLink,
    isBold,
    isItalic,
    isUnderline,
    isStrikethrough,
    isHeading,
    setIsLinkEditMode
}: {
  editor: LexicalEditor;
  anchorElem: HTMLElement;
  isBold: boolean;
  isItalic: boolean;
  isLink: boolean;
  isStrikethrough: boolean;
  isUnderline: boolean;
  isHeading: boolean;
  setIsLinkEditMode: Dispatch<boolean>;
}): JSX.Element {
    const popupCharStylesEditorRef = useRef<HTMLDivElement | null>(null);

    const insertLink = useCallback(() => {
        if (!isLink) {
            setIsLinkEditMode(true);
            editor.dispatchCommand(TOGGLE_LINK_COMMAND, "https://");
        } else {
            setIsLinkEditMode(false);
            editor.dispatchCommand(TOGGLE_LINK_COMMAND, null);
        }
    }, [editor, isLink, setIsLinkEditMode]);

    function mouseMoveListener(e: MouseEvent) {
        if (
            popupCharStylesEditorRef?.current
      && (e.buttons === 1 || e.buttons === 3)
        ) {
            if (popupCharStylesEditorRef.current.style.pointerEvents !== "none") {
                const x = e.clientX;
                const y = e.clientY;
                const elementUnderMouse = document.elementFromPoint(x, y);

                if (!popupCharStylesEditorRef.current.contains(elementUnderMouse)) {
                    // Mouse is not over the target element => not a normal click, but probably a drag
                    popupCharStylesEditorRef.current.style.pointerEvents = "none";
                }
            }
        }
    }
    function mouseUpListener() {
        if (popupCharStylesEditorRef?.current) {
            if (popupCharStylesEditorRef.current.style.pointerEvents !== "auto") {
                popupCharStylesEditorRef.current.style.pointerEvents = "auto";
            }
        }
    }

    useEffect(() => {
        if (popupCharStylesEditorRef?.current) {
            document.addEventListener("mousemove", mouseMoveListener);
            document.addEventListener("mouseup", mouseUpListener);

            return () => {
                document.removeEventListener("mousemove", mouseMoveListener);
                document.removeEventListener("mouseup", mouseUpListener);
            };
        }
    }, [popupCharStylesEditorRef]);

    const $updateTextFormatFloatingToolbar = useCallback(() => {
        const selection = $getSelection();

        const popupCharStylesEditorElem = popupCharStylesEditorRef.current;
        const nativeSelection = window.getSelection();

        if (popupCharStylesEditorElem === null) {
            return;
        }

        const rootElement = editor.getRootElement();
        if (
            selection !== null
      && nativeSelection !== null
      && !nativeSelection.isCollapsed
      && rootElement !== null
      && rootElement.contains(nativeSelection.anchorNode)
        ) {
            const rangeRect = getDOMRangeRect(nativeSelection, rootElement);

            setFloatingElemPosition(
                rangeRect,
                popupCharStylesEditorElem,
                anchorElem,
                isLink
            );
        }
    }, [editor, anchorElem, isLink]);

    useEffect(() => {
        const scrollerElem = anchorElem.parentElement;

        const update = () => {
            editor.getEditorState().read(() => {
                $updateTextFormatFloatingToolbar();
            });
        };

        window.addEventListener("resize", update);
        if (scrollerElem) {
            scrollerElem.addEventListener("scroll", update);
        }

        return () => {
            window.removeEventListener("resize", update);
            if (scrollerElem) {
                scrollerElem.removeEventListener("scroll", update);
            }
        };
    }, [editor, $updateTextFormatFloatingToolbar, anchorElem]);

    useEffect(() => {
        editor.getEditorState().read(() => {
            $updateTextFormatFloatingToolbar();
        });
        return mergeRegister(
            editor.registerUpdateListener(({ editorState }) => {
                editorState.read(() => {
                    $updateTextFormatFloatingToolbar();
                });
            }),

            editor.registerCommand(
                SELECTION_CHANGE_COMMAND,
                () => {
                    $updateTextFormatFloatingToolbar();
                    return false;
                },
                COMMAND_PRIORITY_LOW
            )
        );
    }, [editor, $updateTextFormatFloatingToolbar]);

    return (
        <div
            ref={popupCharStylesEditorRef}
            className="floating-text-format-popup"
        >
            {editor.isEditable() && (
                <React.Fragment>
                    <button
                        aria-label="Format text as bold"
                        className={`popup-item spaced ${isBold ? "active" : ""}`}
                        disabled={isHeading}
                        type="button"
                        onClick={() => {
                            editor.dispatchCommand(FORMAT_TEXT_COMMAND, "bold");
                        }}
                    >
                        <i className="format bold" />
                    </button>
                    <button
                        aria-label="Format text as italics"
                        className={`popup-item spaced ${isItalic ? "active" : ""}`}
                        disabled={isHeading}
                        type="button"
                        onClick={() => {
                            editor.dispatchCommand(FORMAT_TEXT_COMMAND, "italic");
                        }}
                    >
                        <i className="format italic" />
                    </button>
                    <button
                        aria-label="Format text to underlined"
                        className={`popup-item spaced ${isUnderline ? "active" : ""}`}
                        disabled={isHeading}
                        type="button"
                        onClick={() => {
                            editor.dispatchCommand(FORMAT_TEXT_COMMAND, "underline");
                        }}
                    >
                        <i className="format underline" />
                    </button>
                    <button
                        aria-label="Format text with a strikethrough"
                        className={`popup-item spaced ${isStrikethrough ? "active" : ""}`}
                        disabled={isHeading}
                        type="button"
                        onClick={() => {
                            editor.dispatchCommand(FORMAT_TEXT_COMMAND, "strikethrough");
                        }}
                    >
                        <i className="format strikethrough" />
                    </button>

                    <button
                        aria-label="Insert link"
                        className={`popup-item spaced ${isLink ? "active" : ""}`}
                        disabled={isHeading}
                        type="button"
                        onClick={insertLink}
                    >
                        <i className="format link" />
                    </button>
                    <Divider />
                    <button
                        aria-label="Format text to left aligned"
                        className="popup-item spaced "
                        type="button"
                        onClick={() => {
                            editor.dispatchCommand(FORMAT_ELEMENT_COMMAND, "left");
                        }}
                    >
                        <i className="format left-align" />
                    </button>
                    <button
                        aria-label="Format text to center aligned"
                        className="popup-item spaced "
                        type="button"
                        onClick={() => {
                            editor.dispatchCommand(FORMAT_ELEMENT_COMMAND, "center");
                        }}
                    >
                        <i className="format center-align" />
                    </button>
                    <button
                        aria-label="Format text to right aligned"
                        className="popup-item spaced "
                        type="button"
                        onClick={() => {
                            editor.dispatchCommand(FORMAT_ELEMENT_COMMAND, "right");
                        }}
                    >
                        <i className="format right-align" />
                    </button>
                    <button
                        aria-label="Format text to justify aligned"
                        className="popup-item spaced "
                        type="button"
                        onClick={() => {
                            editor.dispatchCommand(FORMAT_ELEMENT_COMMAND, "justify");
                        }}
                    >
                        <i className="format justify-align" />
                    </button>
                </React.Fragment>
            )}
        </div>
    );
}

function useFloatingTextFormatToolbar(
    editor: LexicalEditor,
    anchorElem: HTMLElement,
    setIsLinkEditMode: Dispatch<boolean>
): JSX.Element | null {
    const [isText, setIsText] = useState(false);
    const [isLink, setIsLink] = useState(false);
    const [isBold, setIsBold] = useState(false);
    const [isItalic, setIsItalic] = useState(false);
    const [isUnderline, setIsUnderline] = useState(false);
    const [isStrikethrough, setIsStrikethrough] = useState(false);
    const [isHeading, setIsHeading] = useState(false);

    const updatePopup = useCallback(() => {
        editor.getEditorState().read(() => {
            // Should not to pop up the floating toolbar when using IME input
            if (editor.isComposing()) {
                return;
            }
            const selection = $getSelection();
            const nativeSelection = window.getSelection();
            const rootElement = editor.getRootElement();

            if (
                nativeSelection !== null
        && (!$isRangeSelection(selection)
          || rootElement === null
          || !rootElement.contains(nativeSelection.anchorNode))
            ) {
                setIsText(false);
                return;
            }

            if (!$isRangeSelection(selection)) {
                return;
            }

            const node = getSelectedNode(selection);

            // Update text format
            setIsBold(selection.hasFormat("bold"));
            setIsItalic(selection.hasFormat("italic"));
            setIsUnderline(selection.hasFormat("underline"));
            setIsStrikethrough(selection.hasFormat("strikethrough"));

            // Update links
            const parent = node.getParent();
            if ($isLinkNode(parent) || $isLinkNode(node)) {
                setIsLink(true);
            } else {
                setIsLink(false);
            }

            setIsHeading($isHeadingNode(parent) || ($isHeadingNode(node) && !parent));

            if (
                !$isCodeHighlightNode(selection.anchor.getNode())
        && selection.getTextContent() !== ""
            ) {
                setIsText($isTextNode(node) || $isParagraphNode(node));
            } else {
                setIsText(false);
            }

            const rawTextContent = selection.getTextContent().replace(/\n/g, "");
            if (!selection.isCollapsed() && rawTextContent === "") {
                setIsText(false);
            }
        });
    }, [editor]);

    useEffect(() => {
        document.addEventListener("selectionchange", updatePopup);
        return () => {
            document.removeEventListener("selectionchange", updatePopup);
        };
    }, [updatePopup]);

    useEffect(() => {
        return mergeRegister(
            editor.registerUpdateListener(() => {
                updatePopup();
            }),
            editor.registerRootListener(() => {
                if (editor.getRootElement() === null) {
                    setIsText(false);
                }
            })
        );
    }, [editor, updatePopup]);

    if (!isText) {
        return null;
    }

    return createPortal(
        <TextFormatFloatingToolbar
            anchorElem={anchorElem}
            editor={editor}
            isBold={isBold}
            isHeading={isHeading}
            isItalic={isItalic}
            isLink={isLink}
            isStrikethrough={isStrikethrough}
            isUnderline={isUnderline}
            setIsLinkEditMode={setIsLinkEditMode}
        />,
        anchorElem
    );
}

export default function FloatingTextFormatToolbarPlugin({
    anchorElem = document.body,
    setIsLinkEditMode
}: {
  anchorElem?: HTMLElement;
  setIsLinkEditMode: Dispatch<boolean>;
}): JSX.Element | null {
    const [editor] = useLexicalComposerContext();
    return useFloatingTextFormatToolbar(editor, anchorElem, setIsLinkEditMode);
}
