
import "./Input.css";

import * as React from "react";
import { HTMLInputTypeAttribute } from "react";

type Props = Readonly<{
  "data-test-id"?: string;
  label: string;
  onChange: (val: string) => void;
  placeholder?: string;
  value: string;
  type?: HTMLInputTypeAttribute;
}>;

export default function TextInput({
    label,
    value,
    onChange,
    placeholder = "",
    "data-test-id": dataTestId,
    type = "text"
}: Props): JSX.Element {
    return (
        <div className="Input__wrapper">
            <label
                className="Input__label"
                htmlFor="editor-text-input"
            >
                {label}
            </label>
            <input
                className="Input__input"
                data-test-id={dataTestId}
                id="editor-text-input"
                placeholder={placeholder}
                type={type}
                value={value}
                onChange={(e) => {
                    onChange(e.target.value);
                }}
            />
        </div>
    );
}
