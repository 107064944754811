/* eslint-disable max-len */
/* eslint-disable max-lines */
/* eslint-disable dot-notation */
import { ChallengeTableData } from "@components/challengeTable/challengeTable";
import { ChallengeVisualisationFont } from "@components/fontForm/fontForm";
import { ChallengeFormValues } from "@pages/addChallengesPage/addChallengesPage";
import { ChallengeConditionFormValuesUnion } from "@pages/addChallengesPage/tabs/conditions/challengeConditionsCard/challengeConditionsCard";
import { ChallengeActivityConditionFormValues } from "@pages/addChallengesPage/tabs/conditions/editChallengeActivityCondition/editChallengeActivityCondition";
import { ChallengeFormConditionFormValues } from "@pages/addChallengesPage/tabs/conditions/editChallengeFormCondition/editChallengeFormCondition";
import { ChallengeDurationFormValues } from "@pages/addChallengesPage/tabs/general/sections/challengeBasicSettingsCard";
import { MilestoneEmailFormValues, MilestoneFormValues } from "@pages/addChallengesPage/tabs/milestones/challengeMilestonesCard/challengeMilstonesCard";
import { TieBreaker } from "@pages/addChallengesPage/tabs/prizes/challengePrizesCard/sections/challengeTieBreakerSettings";
import {
    ChallengePrize as FormChallengePrize,
    ChallengeRewards as FormChallengeRewards
} from "@pages/addChallengesPage/tabs/prizes/editChallengePrize/editChallengePrize";
import { ChallengeVisualisationsFormValuesUnion } from "@pages/addChallengesPage/tabs/visualisations/challengeVisualisationsCard/challengeVisualisationsCard";
import {
    ChallengeGridVisualisationColumn,
    ChallengeGridVisualisationFormValues
} from "@pages/addChallengesPage/tabs/visualisations/editChallengeGridVisualisation/editChallengeGridVisualisation";
import {
    ChallengeLeaderboardVisualisationColumn,
    ChallengeLeaderboardVisualisationFormValues,
    ChallengeLeaderboardVisualisationItem
} from "@pages/addChallengesPage/tabs/visualisations/editChallengeLeaderboardVisualisation/editChallengeLeaderboardVisualisation";
import { ChallengeTimelineVisualisationCounter } from "@pages/addChallengesPage/tabs/visualisations/editChallengeTimelineVisualisation/editChallengeTimelineCounter/editChallengeTimelineCounter";
import { ChallengeTimelineVisualisationGoal } from "@pages/addChallengesPage/tabs/visualisations/editChallengeTimelineVisualisation/editChallengeTimelineGoal/editChallengeTimelineGoal";
import { ChallengeTimelineVisualisationRepeater } from "@pages/addChallengesPage/tabs/visualisations/editChallengeTimelineVisualisation/editChallengeTimelineRepeater/editChallengeTimelineRepeater";
import { ChallengeTimelineVisualisationFormValues } from "@pages/addChallengesPage/tabs/visualisations/editChallengeTimelineVisualisation/editChallengeTimelineVisualisation";
import { getFrontendRoles } from "@utils/applicationUtils";
import { formatDate } from "@utils/dateUtils";
import { emptyChallenge, getEmptyChallengeVoucherConfig } from "@utils/emptyItems/emptyChallenge";
import { ChallengeTeamFormValues, emptyChallengeTeam } from "@utils/emptyItems/emptyChallengeTeam";
import { getOutFalseValues } from "@utils/getOutFalseValues";
import { getText } from "@utils/getText";
import { objectTypeCheck } from "@utils/objectTypeCheck";
import { toLanguageObject } from "@utils/toLanguageObject";
import { toTextByLanguage, toTextByLanguageLexicalAdjusted } from "@utils/toTextByLanguage";
import * as environment from "environment";
import * as moment from "moment";
import { UNIT_TYPES } from "../../constants";
import {
    AddChallengeMutationVariables,
    AddChallengeTeamMutationVariables,
    ApplicationFragment,
    Challenge,
    ChallengeActivityCondition,
    ChallengeBasicFragment,
    ChallengeConditionFontVisualisation,
    ChallengeConditionFragment,
    ChallengeConditionGridColumnVisualisation,
    ChallengeConditionGridVisualisation,
    ChallengeConditionInOneGo,
    ChallengeConditionLeaderboardColumnVisualisation,
    ChallengeConditionLeaderboardItemVisualisation,
    ChallengeConditionLeaderboardVisualisation,
    ChallengeConditionLeaderboardVisualisationInput,
    ChallengeConditionRepeat,
    ChallengeConditionTimelineBannerCounterVisualisation,
    ChallengeConditionTimelineBannerGoalVisualisation,
    ChallengeConditionTimelineBannerRepeaterVisualisation,
    ChallengeConditionTimelineVisualisation,
    ChallengeConditionTimespan,
    ChallengeFormCondition,
    ChallengeFragment,
    ChallengeLeaderboardVisualisationInput,
    ChallengeMilestone,
    ChallengeMilestoneFragment,
    ChallengeRewards,
    ChallengeTeam,
    ChallengeTeamConfig,
    ChallengeTieBreaker,
    ChallengeVisualisation,
    FormTemplateFragment,
    GetChallengeTitlesQuery,
    InputMaybe,
    Maybe,
    Scalars,
    SuperDates,
    TextByLanguage,
    UpdateChallengeTeamMutationVariables
} from "../../graphql2/types";

// For some reason these aren't exported in the types.tsx file so they have to be manually copied over.
type ChallengeCondition_ChallengeActivityCondition_Fragment = (
    { __typename?: "ChallengeActivityCondition"; }
    & Pick<ChallengeActivityCondition, "activities" | "value" | "unitType" | "unit" | "id" | "sequence" | "type" | "startTime" | "endTime"|"credits">
    & {
        inOneGo?: Maybe<(
            { __typename?: "ChallengeConditionInOneGo"; }
            & Pick<ChallengeConditionInOneGo, "enabled" | "repeat">
        )>; timespan?: Maybe<(
            { __typename?: "ChallengeConditionTimespan"; }
            & Pick<ChallengeConditionTimespan, "enabled" | "value" | "unit">
        )>; repeated?: Maybe<(
            { __typename?: "ChallengeConditionRepeat"; }
            & Pick<ChallengeConditionRepeat, "enabled" | "value" | "sequential">
        )>;
    }
);

type ChallengeCondition_ChallengeFormCondition_Fragment = (
    { __typename?: "ChallengeFormCondition"; }
    & Pick<ChallengeFormCondition, "image" | "id" | "sequence" | "type" | "startTime" | "endTime">
    & {
        formTemplate?: Maybe<(
            { __typename?: "FormTemplate"; }
            & FormTemplateFragment
        )>;
    }
);

export function mapMilestones(milestones?: ChallengeFragment["milestones"] | null): MilestoneFormValues[] {
    if (!milestones || !milestones.length) {
        return [];
    }

    return milestones.map((milestone: ChallengeMilestoneFragment) => {
        const { id, conditionId, percentage, title, description, email, imageUrl, notificationsEnabled } = milestone;

        return {
            id: id || "",
            conditionId: conditionId || "",
            percentage: percentage || 0,
            title: toLanguageObject(title),
            description: toLanguageObject(description),
            email: email ? {
                subject: toLanguageObject(email.subject),
                body: toLanguageObject(email.body)
            } : undefined,
            imageUrl: imageUrl || "",
            mailEnabled: Boolean(email && email.subject && email.subject.length),
            notificationsEnabled: notificationsEnabled ?? true
        };
    });
}

function mapPrizesToFormValues(prizes?: ChallengeRewards["prizes"]) {
    if (!prizes) {
        return [];
    }

    return prizes.map(prize => {
        return {
            ...prize,
            imageUrl: (prize && prize.imageUrl) || "",
            title: toLanguageObject((prize && prize.title) ? prize.title : []),
            description: toLanguageObject((prize && prize.description) ? prize.description : [])
        };
    });
}

function mapRewardsToFormValues(rewards?: ChallengeRewards | null) {
    if (!rewards) {
        return {
            credits: 0,
            prizes: []
        };
    }

    return {
        credits: rewards.credits || 0,
        prizes: mapPrizesToFormValues(rewards.prizes)
    };
}
function mapTeam(team?: ChallengeTeamConfig | null) {
    if (!team) {
        return {
            enabled: false,
            fixedTeams: false,
            maxMembers: 0,
            profileProperty: "",
            inviteCodeEnabled: false
        };
    }

    return {
        enabled: team.enabled || false,
        fixedTeams: team.fixedTeams || false,
        maxMembers: team.maxMembers || 0,
        profileProperty: team.profileProperty || "",
        inviteCodeEnabled: team.inviteCodeEnabled || false
    };
}

function mapTieBreaker(tieBreaker?: ChallengeTieBreaker | null): TieBreaker {
    if (!tieBreaker) {
        return {
            active: false,
            ask: "captain",
            title: {},
            description: {},
            question1: {},
            question2: {}
        };
    }
    const { forAllTeamMembers, ...restTieBreaker } = tieBreaker;

    return {
        ...restTieBreaker,
        active: tieBreaker.active || false,
        ask: forAllTeamMembers ? "allMembers" : "captain",
        title: toLanguageObject(tieBreaker.title ? tieBreaker.title : []),
        description: toLanguageObject(tieBreaker.description ? tieBreaker.description : []),
        question1: toLanguageObject(tieBreaker.question1 ? tieBreaker.question1 : []),
        question2: toLanguageObject(tieBreaker.question2 ? tieBreaker.question2 : [])
    };
}

function mapEndless(endless?: ChallengeFragment["endless"] | null) {
    if (!endless) {
        return {
            activityTypes: [],
            enabled: false,
            rankingAlgorithm: "",
            unitType: ""
        };
    }

    const activityTypesWithoutNull: string[] | null | undefined = endless.activityTypes && endless.activityTypes.filter(at => at !== null) as string[];

    return {
        activityTypes: activityTypesWithoutNull || [],
        enabled: endless.enabled || false,
        rankingAlgorithm: endless.rankingAlgorithm || "",
        unitType: endless.unitType || ""
    };
}

function mapFormValueToPrize(prizes?: FormChallengePrize[] | null) {
    if (!prizes) {
        return [];
    }

    return prizes.map(prize => {
        return {
            ...prize,
            title: toTextByLanguage(prize.title),
            description: toTextByLanguage(prize.description)
        };
    });
}

function mapFormValuesToRewards(rewards?: FormChallengeRewards | null) {
    if (!rewards) {
        return {};
    }

    return {
        credits: rewards.credits,
        prizes: mapFormValueToPrize(rewards.prizes)
    };
}

const emptyTieBreaker: TieBreaker = {
    active: false,
    ask: "captain",
    title: {},
    description: {},
    question1: {},
    question2: {}
};

function mapFormValuesToTieBreaker(tieBreaker: TieBreaker = emptyTieBreaker) {
    if (!tieBreaker) {
        return {};
    }
    const { ask, ...restTieBreaker } = tieBreaker;

    return {
        ...restTieBreaker,
        active: Boolean(tieBreaker.active),
        forAllTeamMembers: ask === "allMembers",
        title: toTextByLanguage(tieBreaker.title ? tieBreaker.title : {}),
        description: toTextByLanguage(tieBreaker.description ? tieBreaker.description : {}),
        question1: toTextByLanguage(tieBreaker.question1 ? tieBreaker.question1 : {}),
        question2: toTextByLanguage(tieBreaker.question2 ? tieBreaker.question2 : {})
    };
}

function mapFontToFormValues(font?: ChallengeConditionFontVisualisation | null): ChallengeVisualisationFont {
    if (!font) {
        return {
            color: "",
            family: "",
            size: ""
        };
    }

    return {
        color: font.color || "",
        family: font.family || "",
        size: font.size || ""
    };
}

function mapBannerCountersToFormValues(counters?: ChallengeConditionTimelineBannerCounterVisualisation[]): ChallengeTimelineVisualisationCounter[] {
    if (!counters) {
        return [];
    }

    return counters.map(counter => ({
        image: counter.image || "",
        font: mapFontToFormValues(counter.font),
        unitType: counter.unitType || "",
        activityTypes: counter.activityTypes?.filter(getOutFalseValues) || []
    }));
}

function mapBannerGoalToFormValues(goal?: ChallengeConditionTimelineBannerGoalVisualisation | null): ChallengeTimelineVisualisationGoal {
    if (!goal) {
        return {
            image: "",
            font: mapFontToFormValues(),
            currentValue: false,
            endValue: false
        };
    }

    return {
        image: goal.image || "",
        font: mapFontToFormValues(goal.font),
        currentValue: goal.currentValue || false,
        endValue: goal.endValue || false
    };
}

function mapBannerRepeaterToFormValues(repeater?: ChallengeConditionTimelineBannerRepeaterVisualisation | null): ChallengeTimelineVisualisationRepeater {
    if (!repeater) {
        return {
            image: "",
            repeat: 0,
            currentFont: mapFontToFormValues(),
            totalFont: mapFontToFormValues()
        };
    }

    return {
        image: repeater.image || "",
        repeat: repeater.repeat || 0,
        currentFont: mapFontToFormValues(repeater.currentFont),
        totalFont: mapFontToFormValues(repeater.totalFont)
    };
}

function mapLeaderboardItemToFormValues(item?: ChallengeConditionLeaderboardItemVisualisation | null): ChallengeLeaderboardVisualisationItem {
    if (!item) {
        return {
            top: {
                count: 0,
                enabled: false
            },
            unit: ""
        };
    }

    return {
        unit: item.unit || "",
        top: item.top
            ? {
                count: item.top.count || 0,
                enabled: true
            }
            : {
                count: 0,
                enabled: false
            }
    };
}

function mapLeaderboardColumns(columns?: ChallengeConditionLeaderboardColumnVisualisation[] | null): ChallengeLeaderboardVisualisationColumn[] {
    if (!columns) {
        return [];
    }

    return columns.map(column => ({
        header: toLanguageObject(column.header),
        profileProperty: column.profileProperty || "",
        filterEnabled: column.filterEnabled || false
    }));
}

function mapGridColumns(columns?: ChallengeConditionGridColumnVisualisation[] | null): ChallengeGridVisualisationColumn[] {
    if (!columns) {
        return [];
    }

    return columns.map(column => ({
        header: toLanguageObject(column.header),
        conditionId: column.conditionId || column.profileProperty || "",
        columnType: column.columnType || ""
    }));
}

function mapChallengeVisualisationsToVisualisationFormValues(visualisations?: ChallengeFragment["visualisations"] | null): ChallengeVisualisationsFormValuesUnion[] {
    if (!visualisations || !visualisations.length) {
        return [];
    }

    const mappedVisualisations: ChallengeVisualisationsFormValuesUnion[] = [];

    visualisations.forEach(visualisation => {
        if (!visualisation) {
            return;
        }

        if (!visualisation) {
            return;
        }

        const shared = {
            type: visualisation.type || "",
            id: String(visualisation.id),
            place: visualisation.place || "",
            dataType: visualisation.dataType || "",
            description: toLanguageObject(visualisation.description),
            title: toLanguageObject(visualisation.title)
        };

        if (objectTypeCheck<ChallengeVisualisation>(visualisation, "timeline")) {
            if (visualisation.conditions) {
                visualisation.conditions.forEach((condition: ChallengeConditionTimelineVisualisation) => {
                    const { banner, flag, conditionId, foregroundImage, backgroundImage, splitterImage, successImage } = condition;

                    mappedVisualisations.push({
                        ...condition,
                        ...shared,
                        clickable: visualisation.clickable || false,
                        conditionId: conditionId || "",
                        flag: flag ? {
                            font: mapFontToFormValues(flag.font),
                            image: flag.image || ""
                        } : undefined,
                        banner: banner ? {
                            backgroundColor: banner.backgroundColor || "",
                            counters: mapBannerCountersToFormValues(banner.counters?.filter(getOutFalseValues)),
                            goal: banner.goal ? mapBannerGoalToFormValues(banner.goal) : undefined,
                            image: banner.image || "",
                            repeater: banner.repeater ? mapBannerRepeaterToFormValues(banner.repeater) : undefined
                        } : undefined,
                        foregroundImage: foregroundImage || "",
                        backgroundImage: backgroundImage || "",
                        splitterImage: splitterImage || "",
                        successImage: successImage || ""
                    });
                });
            }
        }

        if (objectTypeCheck<ChallengeVisualisation>(visualisation, "leaderboard")) {
            if (visualisation.conditions) {
                const { type, place, dataType, clickable, conditions } = visualisation;
                const condition = conditions && conditions[0] as ChallengeConditionLeaderboardVisualisation;

                if (condition.conditionId) {
                    const index = mappedVisualisations.findIndex((v: ChallengeLeaderboardVisualisationFormValues) => v.type === "leaderboard" && v.dataType === visualisation.dataType && !v.isGlobal);
                    const mappedCondition = {
                        id: visualisation.id,
                        conditionId: String(condition.conditionId),
                        title: toLanguageObject(visualisation.title),
                        columnNames: condition.columns?.map(column => column?.profileProperty || ""),
                        enableFilters: condition.columns?.some(column => column?.filterEnabled),
                        columns: mapLeaderboardColumns((condition.columns || []).filter(getOutFalseValues))
                    };
                    if (index < 0) {
                        // doesn't exist yet, push new entry
                        const newVisualisation: ChallengeLeaderboardVisualisationFormValues = {
                            type,
                            place,
                            dataType,
                            clickable,
                            conditionId: "conditions",
                            isGlobal: false,
                            numberOfItems: Number(condition.numberOfItems),
                            numberOfItemsEnabled: Number(condition.numberOfItems) !== 0,
                            description: toLanguageObject(visualisation.description),
                            conditions: [mappedCondition]
                        };

                        mappedVisualisations.push(newVisualisation);
                    } else {
                        (mappedVisualisations[index] as ChallengeLeaderboardVisualisationFormValues).conditions.push(mappedCondition);
                    }
                } else {
                    const newVisualisation: ChallengeLeaderboardVisualisationFormValues = {
                        id: visualisation.id || "",
                        type,
                        place,
                        dataType,
                        clickable,
                        conditionId: "global",
                        isGlobal: true,
                        numberOfItems: Number(condition.numberOfItems),
                        numberOfItemsEnabled: Number(condition.numberOfItems) !== 0,
                        conditions: [{
                            id: visualisation.id,
                            conditionId: "global",
                            columnNames: condition.columns?.map(column => column?.profileProperty || ""),
                            title: toLanguageObject(visualisation.title),
                            enableFilters: condition.columns?.some(column => column?.filterEnabled),
                            columns: mapLeaderboardColumns((condition.columns || []).filter(getOutFalseValues))
                        }],
                        availableUnitTypeRankings: condition.availableUnitTypeRankings || [],
                        item: mapLeaderboardItemToFormValues(condition.item),
                        globalSettings: {
                            title: {}, // toLanguageObject(visualisation.description),
                            description: toLanguageObject(visualisation.description)
                        }
                    };

                    mappedVisualisations.push(newVisualisation);
                }
            }
        }

        if (objectTypeCheck<ChallengeVisualisation>(visualisation, "grid")) {
            if (visualisation.conditions) {
                visualisation.conditions.forEach((condition: ChallengeConditionGridVisualisation) => {
                    const { columns, order, images } = condition;

                    mappedVisualisations.push({
                        ...shared,
                        clickable: visualisation.clickable || false,
                        conditionId: "global",
                        columns: mapGridColumns(columns?.filter(getOutFalseValues)),
                        order: order || "",
                        images: {
                            notStarted: images?.notStarted || null,
                            inProgress: images?.inProgress || null,
                            success: images?.success || null
                        }
                    });
                });
            }
        }
    });

    return mappedVisualisations;
}

function mapFormConditionToFormValues(
    // eslint-disable-next-line camelcase
    condition: ChallengeCondition_ChallengeFormCondition_Fragment,
    base: BaseCondition
): ChallengeConditionFormValuesUnion {
    const { formTemplate, image } = condition;

    return {
        ...base,
        image: image || "",
        formTemplate: {
            id: (formTemplate && formTemplate.id) || "",
            elements: formTemplate && formTemplate.elements
                ? formTemplate.elements.map((element) => {
                    return {
                        ...element,
                        title: toLanguageObject(element && element.title),
                        defaultValue: (element && element.defaultValue) || "",
                        property: (element && element.property) || "",
                        type: (element && element.type) || "",
                        required: (element && element.required) || false,
                        options: element && element.options
                            ? element.options.map(option => ({
                                value: (option && option.value) || "",
                                title: toLanguageObject(option && option.title)
                            }))
                            : []
                    };
                })
                : [],
            title: toLanguageObject(formTemplate && formTemplate.title),
            errorMessages: toLanguageObject(formTemplate && formTemplate.errorMessages)
        }
    };
}

function mapActivityConditionToFormValues(condition: ChallengeCondition_ChallengeActivityCondition_Fragment, base: BaseCondition): ChallengeConditionFormValuesUnion {
    const { activities, unit, unitType, value, repeated, timespan, inOneGo, credits } = condition;

    return {
        ...base,
        activities: activities?.filter(getOutFalseValues) || [],
        unit: unit || "",
        unitType: unitType || "",
        value: value || 0,
        repeated: repeated
            ? {
                enabled: repeated.enabled || false,
                sequential: repeated.sequential || false,
                value: repeated.value || 0
            }
            : {
                enabled: false,
                sequential: false,
                value: 0
            },
        timespan: timespan
            ? {
                enabled: timespan.enabled || false,
                unit: timespan.unit || "",
                value: timespan.value || 0
            }
            : {
                enabled: false,
                unit: "",
                value: 0
            },
        inOneGo: inOneGo
            ? {
                enabled: inOneGo.enabled || false,
                repeat: inOneGo.repeat || 0
            }
            : {
                enabled: false,
                repeat: 0
            },
        credits: credits || 0
    };
}

function challengeConditionsToConditionFormValues(conditions: ChallengeFragment["conditions"] | null): ChallengeConditionFormValuesUnion[] {
    const mapped: ChallengeConditionFormValuesUnion[] = [];

    if (!conditions || !conditions.length) {
        return [];
    }

    conditions.forEach((condition, index) => {
        if (!condition) {
            return null;
        }

        const { id, type, startTime, endTime } = condition;

        const base: BaseCondition = {
            id: id || "",
            type: type || "",
            startTime: startTime ? moment(startTime) : null,
            endTime: endTime ? moment(endTime) : null,
            // V1 challenges have duplicates in sequence, breaking the ant forms. Use the index to overwrite it.
            sequence: index
        };

        if (objectTypeCheck<ChallengeCondition_ChallengeFormCondition_Fragment>(condition, "form")) {
            mapped.push(mapFormConditionToFormValues(condition, base));
        }

        if (objectTypeCheck<ChallengeCondition_ChallengeActivityCondition_Fragment>(condition, "activity")) {
            mapped.push(mapActivityConditionToFormValues(condition, base));
        }
    });

    return mapped;
}

export function conditionFormValuesToChallengeConditions(conditions?: ChallengeConditionFormValuesUnion[]): ChallengeConditionFragment[] {
    if (!conditions) {
        return [];
    }

    const mapped: ChallengeConditionFragment[] = [];

    conditions.forEach((condition, index) => {
        const { id, type, startTime, endTime } = condition;

        const base = {
            id,
            sequence: index,
            type,
            startTime: startTime ? formatDate(startTime) : null,
            endTime: endTime ? formatDate(endTime) : null
        };

        if (type === "form") {
            const c = condition as ChallengeFormConditionFormValues;
            const { image } = c;
            const formTemplate = c.formTemplate || {};
            const elements = formTemplate.elements || [];

            mapped.push({
                ...base,
                image,
                formTemplate: {
                    ...formTemplate,
                    title: toTextByLanguage(formTemplate.title),
                    errorMessages: toTextByLanguage(formTemplate.errorMessages),
                    elements: elements.map(element => ({
                        ...element,
                        title: toTextByLanguage(element.title),
                        options: element.options && element.options.map(option => ({
                            ...option,
                            title: toTextByLanguage(option.title)
                        }))
                    }))
                }
            });
        }

        if (type === "activity") {
            const { activities, value, unit, inOneGo, timespan, repeated, credits } = condition as ChallengeActivityConditionFormValues;

            const unitType = UNIT_TYPES.find(ut => ut.values.includes(unit));

            mapped.push({
                ...base,
                activities,
                value,
                unitType: (unitType && unitType.group.toLowerCase()) || "",
                unit,
                inOneGo,
                timespan,
                repeated,
                credits
            });
        }
    });

    return mapped;
}

function getMilestoneEmail(mailEnabled: boolean, email: MilestoneEmailFormValues | undefined): {subject: TextByLanguage[]; body: TextByLanguage[];} | null {
    if (!(mailEnabled && email)) {
        return null;
    }
    const emptyValue = { en: " " };
    const subject = Object.keys(email.subject).length ? email.subject : emptyValue;
    const body = Object.keys(email.body).length ? email.body : emptyValue;

    return {
        subject: toTextByLanguage(subject),
        body: toTextByLanguage(body)
    };
}

function mapFormValuesToMilestones(formValues?: MilestoneFormValues[]): ChallengeMilestone[] {
    if (!formValues) {
        return [];
    }

    return formValues.map(milestone => {
        const { id, title, imageUrl, mailEnabled, conditionId, description, email, percentage, notificationsEnabled } = milestone;

        return {
            percentage,
            id,
            conditionId: conditionId || null,
            title: toTextByLanguage(title),
            imageUrl,
            description: toTextByLanguage(description),
            notificationsEnabled,
            email: getMilestoneEmail(mailEnabled, email)
        };
    });
}

function mapFormValuesToVisualisations(formValues?: ChallengeVisualisationsFormValuesUnion[]): Scalars["ChallengeVisualisationInput"]["input"] {
    if (!formValues) {
        return [];
    }

    // split up leaderboard visualisations
    const leaderboardFormValues = formValues.filter(visualisation => objectTypeCheck<ChallengeLeaderboardVisualisationFormValues>(visualisation, "leaderboard"));
    const otherFormValues = formValues.filter(visualisation => !objectTypeCheck<ChallengeLeaderboardVisualisationFormValues>(visualisation, "leaderboard"));

    const mappedLeaderboard: ChallengeLeaderboardVisualisationInput[] = [];

    leaderboardFormValues.forEach((leaderboard: ChallengeLeaderboardVisualisationFormValues) => {
        const { id, type, place, dataType, clickable, numberOfItems, isGlobal, availableUnitTypeRankings } = leaderboard;

        const leaderboardVisualisation = leaderboard as ChallengeLeaderboardVisualisationFormValues;
        delete leaderboardVisualisation.numberOfItemsEnabled;
        if (leaderboardVisualisation.item) {
            delete leaderboardVisualisation.item.top.enabled;
        }

        if (isGlobal) {
            const condition = {
                numberOfItems,
                item: leaderboardVisualisation.item,
                conditionId: null,

                columns: leaderboard.conditions[0] ? leaderboard.conditions[0].columns?.map((column) => ({
                    ...column,
                    filterEnabled: leaderboard.conditions[0].enableFilters,
                    header: toTextByLanguage(column.header)
                })) : [],
                availableUnitTypeRankings: availableUnitTypeRankings?.length ? availableUnitTypeRankings : null
            } as ChallengeConditionLeaderboardVisualisationInput;

            const newVisualisation = {
                id,
                type,
                place,
                dataType,
                clickable,
                // title: toTextByLanguage(leaderboard.globalSettings.title) (could be added later)
                description: (leaderboard.globalSettings && toTextByLanguage(leaderboard.globalSettings.description)) || [],
                conditions: condition ? [condition] : []
            };

            mappedLeaderboard.push(newVisualisation);
        } else {
            // add every single condition
            const sharedVisualisation = {
                type,
                place,
                dataType,
                clickable
            };

            leaderboard.conditions.forEach(condition => {
                const mappedCondition = {
                    numberOfItems,
                    item: leaderboardVisualisation.item,
                    conditionId: condition.conditionId,
                    columns: condition.columns ? condition.columns.map((column) => ({
                        ...column,
                        filterEnabled: condition.enableFilters,
                        header: toTextByLanguage(column.header)
                    })) : []
                } as ChallengeConditionLeaderboardVisualisation;

                mappedLeaderboard.push({
                    id: condition.id,
                    ...sharedVisualisation,
                    title: toTextByLanguage(condition.title),
                    description: toTextByLanguage(leaderboard.description),
                    conditions: [mappedCondition]
                });
            });
        }
    });

    const mapped = otherFormValues.map(visualisation => {
        const { id, type, place, dataType, clickable, title, description, ...specificVisualisation } = visualisation;

        let condition: ChallengeConditionTimelineVisualisation | ChallengeConditionLeaderboardVisualisation | ChallengeConditionGridVisualisation | undefined;

        // enable type assertion zo we get correct type checking on the created conditions
        // tslint:disable:no-object-literal-type-assertion
        if (objectTypeCheck<ChallengeTimelineVisualisationFormValues>(visualisation, "timeline")) {
            const timelinevisualisation = specificVisualisation as ChallengeConditionTimelineVisualisation;

            condition = {
                ...specificVisualisation,
                conditionId: visualisation.conditionId || null,
                banner: {
                    ...timelinevisualisation.banner,
                    counters: timelinevisualisation?.banner?.counters?.filter((counter) => counter && (Boolean(counter.image))) || []
                }
            } as ChallengeConditionTimelineVisualisation;
        }

        if (objectTypeCheck<ChallengeGridVisualisationFormValues>(visualisation, "grid")) {
            const { columns, images } = visualisation;

            condition = {
                ...specificVisualisation,
                conditionId: null,
                columns: columns ? columns.map(column => {
                    const isProfileField = environment.profileFields?.some(field => field.key === column.conditionId);

                    return {
                        ...column,
                        conditionId: isProfileField ? "" : column.conditionId,
                        profileProperty: isProfileField ? column.conditionId : "",
                        header: toTextByLanguage(column.header)
                    };
                }) : [],
                images: {
                    notStarted: images?.notStarted || null,
                    inProgress: images?.inProgress || null,
                    success: images?.success || null
                }
            } as ChallengeConditionGridVisualisation;
        }

        return {
            id,
            type,
            place,
            dataType,
            clickable,
            title: toTextByLanguage(title),
            description: toTextByLanguage(description),
            conditions: condition ? [condition] : []
        };
    });

    return [
        ...mappedLeaderboard,
        ...mapped
    ];
}

function getTeamPropertyValuesFromUserRoles(apps: ApplicationFragment[] | undefined): AddChallengeMutationVariables["teamPropertyValues"] {
    let teamPropertyValues: InputMaybe<string>[] | undefined;
    const roles = getFrontendRoles(apps);
    if (roles.length) {
        teamPropertyValues = [];
        roles.forEach(role => {
            teamPropertyValues?.push(role.id);
        });
    }

    return teamPropertyValues;
}

function getTeamPropertyValuesFromProfileField(profileProperty: string): AddChallengeMutationVariables["teamPropertyValues"] {
    let teamPropertyValues: InputMaybe<string>[] | undefined;
    const field = environment.profileFields?.find((fieldName) => fieldName.key === profileProperty);

    if (field && field.options) {
        teamPropertyValues = [];
        field.options.forEach(option => {
            teamPropertyValues?.push(`${option.value}`);
        });
    }

    return teamPropertyValues;
}

export function mapFormValuestoTeamPropertyValues(team: ChallengeFormValues["team"], apps: ApplicationFragment[] | undefined): AddChallengeMutationVariables["teamPropertyValues"] {
    const { fixedTeams, profileProperty } = team;
    let teamPropertyValues: AddChallengeMutationVariables["teamPropertyValues"];

    if (!fixedTeams || !profileProperty) {
        return teamPropertyValues;
    }

    if (profileProperty === "frontendrole") {
        teamPropertyValues = getTeamPropertyValuesFromUserRoles(apps);
    } else {
        teamPropertyValues = getTeamPropertyValuesFromProfileField(profileProperty);
    }

    return teamPropertyValues;
}

function mapFormValuesToSuperDays(superDays: ChallengeFormValues["superDays"]): AddChallengeMutationVariables["superDates"] {
    if (!superDays) {
        return;
    }

    const mapDateRanges = (dates: ChallengeFormValues["superDays"]["dates"]) => {
        if (!dates) {
            return [];
        }

        return dates
            .filter(date => date.from && date.to)
            .map((date) => ({
                from: date.from.startOf("day").valueOf(),
                to: date.to.startOf("day").valueOf()
            }));
    };

    return {
        dates: mapDateRanges(superDays.dates),
        multiplier: superDays.multiplier,
        title: toTextByLanguage(superDays.title),
        description: toTextByLanguage(superDays.description)
    };
}

// tslint:disable-next-line:max-func-body-length
export function formValuesToChallenge(formValues: ChallengeFormValues): AddChallengeMutationVariables {
    const {
        title,
        subtitle,
        description,
        imageUrl,
        duration,
        conditions,
        ultimateSyncTime,
        publishedOn,
        expiresOn,
        hideProgressInDetails,
        exceedConditionLimit,
        hideProgressInInsights,
        hideProgressInList,
        hideInList,
        showFullDescription,
        priority,
        featured,
        milestones,
        team,
        rewards,
        endless,
        registration,
        roles,
        conditionsInOrder,
        difficultyLevel,
        tieBreaker,
        visualisations,
        tags,
        activityTypes,
        viewParticipants,
        viewParticipantCount,
        viewCompletedParticipants,
        viewTeamCount,
        setCustomExpire,
        enabledLocales,
        challengeVoucherConfiguration: { description: voucherDesc, title: voucherTitle, url: voucherUrl, required: voucherRequired },
        totals,
        data,
        relatedContentId,
        superDays,
        autoParticipationEnabled,
        disableComments
    } = formValues;

    return {
        title: toTextByLanguage(title),
        subtitle: toTextByLanguage(subtitle),
        description: toTextByLanguageLexicalAdjusted(description),
        conditionsInOrder,
        imageUrl: imageUrl || "",
        duration: {
            start: formatDate(duration.start),
            end: formatDate(duration.end)
        },
        enabledLocales,
        exceedConditionLimit,
        difficultyLevel,
        conditions: conditionFormValuesToChallengeConditions(conditions),
        ultimateSyncTime: formatDate(ultimateSyncTime, true),
        publishedOn: formatDate(publishedOn),
        expiresOn: expiresOn && setCustomExpire ? formatDate(expiresOn) : null,
        hideProgressInList,
        hideProgressInDetails,
        hideProgressInInsights,
        hideInList,
        priority,
        roles,
        featured,
        endless,
        team: {
            enabled: team.enabled,
            fixedTeams: team.fixedTeams,
            profileProperty: team.profileProperty,
            maxMembers: team.maxMembers,
            inviteCodeEnabled: team.inviteCodeEnabled
        },
        viewTeamCount: Boolean(viewTeamCount),
        registration: registration || null,
        rewards: mapFormValuesToRewards(rewards),
        tieBreaker: mapFormValuesToTieBreaker(tieBreaker),
        milestones: mapFormValuesToMilestones(milestones),
        visualisations: mapFormValuesToVisualisations(visualisations),
        tags: tags || [],
        activityTypes: activityTypes || [],
        viewParticipants,
        viewParticipantCount,
        viewCompletedParticipants,
        voucherConfiguration: {
            required: voucherRequired,
            description: voucherDesc ? Object.keys(voucherDesc).map(lang => ({ language: lang, text: voucherDesc[lang].text || "" })) : null,
            title: voucherTitle ? Object.keys(voucherTitle).map(lang => ({ language: lang, text: voucherTitle[lang].text || "" })) : null,
            url: voucherUrl
        },
        totals,
        data: {
            ...data,
            showFullDescription
        },
        contentsIds: relatedContentId,
        superDates: mapFormValuesToSuperDays(superDays),
        autoParticipationEnabled,
        disableComments
    };
}

interface BaseCondition {
    id: string;
    type: string;
    startTime?: moment.Moment | null;
    endTime?: moment.Moment | null;
    sequence: number;
}

export function translateChallenges(originals: ChallengeBasicFragment[]): ChallengeTableData[] {
    return originals.map((challenge): ChallengeTableData => {
        const { id, title, activityTypes, duration, team, roles } = challenge;

        return {
            id,
            title: getText(title || undefined),
            visible: true,
            activity: activityTypes ? activityTypes.join(", ") : "",
            start: duration ? duration.start || 0 : 0,
            end: duration ? duration.end || 0 : 0,
            hasTeam: Boolean(team && team.enabled),
            requireVoucher: challenge.voucherConfiguration && challenge.voucherConfiguration.required,
            roles: roles?.filter(getOutFalseValues) ?? []
        };
    });
}

export interface TranslatedChallengeTitles {
    id: string;
    title: string;
    visualisations?: ChallengeVisualisation[] | null;
}

export const translateChallengeTitles = (originals: GetChallengeTitlesQuery["challenges"]): TranslatedChallengeTitles[] => {
    return originals.map((challenge) => {
        const { id, title, visualisations } = challenge;

        return {
            id,
            title: title ? getText(title) : "",
            visualisations: visualisations?.filter(getOutFalseValues) || []
        };
    });
};

export const challengeTeamToFormValues = (team?: ChallengeTeam | null): ChallengeTeamFormValues => {
    if (!team) {
        return emptyChallengeTeam;
    }

    const {
        id,
        title,
        description,
        profileValue,
        contentId,
        imageUrl
    } = team;

    return {
        id: id || "",
        title: title || "",
        description: toLanguageObject(description),
        profileValue: profileValue || "",
        contentId: contentId || "",
        imageUrl: imageUrl || ""
    };
};

export function formValuesToAddChallengeTeam(formValues: ChallengeTeamFormValues & { challengeId: string; }): AddChallengeTeamMutationVariables {
    const {
        title,
        profileValue,
        description,
        imageUrl,
        contentId,
        challengeId
    } = formValues;

    return {
        title,
        challengeId,
        profileValue,
        description: toTextByLanguage(description),
        imageUrl,
        contentId
    };
}

export function formValuesToUpdateChallengeTeam(formValues: ChallengeTeamFormValues & { id: string; }): UpdateChallengeTeamMutationVariables {
    const {
        title,
        profileValue,
        description,
        imageUrl,
        contentId,
        id
    } = formValues;

    return {
        id,
        title,
        profileValue,
        description: toTextByLanguage(description),
        imageUrl: imageUrl || "",
        contentId: contentId || ""
    };
}

function mapSuperDaysToFormValues(superDays: Challenge["superDates"]): ChallengeFormValues["superDays"] {
    if (!superDays) {
        return {
            dates: [],
            multiplier: 1,
            title: {},
            description: {}
        };
    }

    const mapDateRanges = (dates: SuperDates["dates"]): ChallengeFormValues["superDays"]["dates"] => {
        if (!dates) {
            return [];
        }

        return dates.map((date) => ({
            from: moment(date.from),
            to: moment(date.to)
        }));
    };

    return {
        dates: mapDateRanges(superDays.dates),
        multiplier: superDays.multiplier ?? 1,
        description: toLanguageObject(superDays.description),
        title: toLanguageObject(superDays.title)
    };
}

// eslint-disable-next-line complexity
export function challengeToFormValues(challenge?: ChallengeFragment | null): ChallengeFormValues {
    if (!challenge) {
        return emptyChallenge;
    }

    const {
        id,
        title,
        subtitle,
        description,
        imageUrl,
        conditions,
        duration,
        registration,
        ultimateSyncTime,
        publishedOn,
        expiresOn,
        hideProgressInDetails,
        hideProgressInInsights,
        hideProgressInList,
        conditionsInOrder,
        hideInList,
        priority,
        roles,
        featured,
        endless,
        team,
        milestones,
        rewards,
        difficultyLevel,
        exceedConditionLimit,
        tieBreaker,
        visualisations,
        tags,
        activityTypes,
        viewTeamCount,
        viewParticipants,
        viewParticipantCount,
        voucherConfiguration,
        viewCompletedParticipants,
        enabledLocales,
        totals,
        relatedContents,
        superDates,
        autoParticipationEnabled,
        disableComments
    } = challenge;

    const challengeDuration: ChallengeDurationFormValues = {
        start: moment(),
        end: moment()
    };

    if (duration) {
        if (duration.start) {
            challengeDuration.start = moment(duration.start);
        }
        if (duration.end) {
            challengeDuration.end = moment(duration.end);
        }
    }

    const rolesWithoutNull: string[] | null | undefined = roles && roles.filter(r => r !== null) as string[];
    const activityTypesWithoutNull: string[] | null | undefined = activityTypes && activityTypes.filter(at => at !== null) as string[];

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { showFullDescription, ...extraData } = challenge.data;

    return {
        id,
        title: toLanguageObject(title),
        subtitle: toLanguageObject(subtitle),
        description: toLanguageObject(description),
        imageUrl: imageUrl || "",
        conditionsInOrder: conditionsInOrder || false,
        duration: challengeDuration,
        registration: {
            participateOnStart: Boolean(registration && registration.participateOnStart)
        },
        ultimateSyncTime: moment(ultimateSyncTime || undefined),
        publishedOn: moment(publishedOn || undefined),
        expiresOn: expiresOn ? moment(expiresOn) : undefined,
        setCustomExpire: Boolean(expiresOn),
        hideProgressInList: Boolean(hideProgressInList),
        hideProgressInDetails: Boolean(hideProgressInDetails),
        hideProgressInInsights: Boolean(hideProgressInInsights),
        hideInList: Boolean(hideInList),
        showFullDescription: Boolean(challenge.data.showFullDescription ?? false),
        priority: priority || 0,
        roles: rolesWithoutNull || [],
        endless: mapEndless(endless),
        featured: Boolean(featured),
        team: mapTeam(team),
        milestones: mapMilestones(milestones),
        rewards: mapRewardsToFormValues(rewards),
        conditions: challengeConditionsToConditionFormValues(conditions || []),
        difficultyLevel: difficultyLevel || 0,
        exceedConditionLimit: Boolean(exceedConditionLimit),
        tieBreaker: mapTieBreaker(tieBreaker),
        visualisations: mapChallengeVisualisationsToVisualisationFormValues(visualisations),
        tags: tags ? tags.map(tag => tag.id) : [],
        activityTypes: activityTypesWithoutNull || [],
        viewParticipants: viewParticipants || false,
        viewTeamCount: Boolean(viewTeamCount),
        viewParticipantCount: viewParticipantCount || false,
        viewCompletedParticipants: viewCompletedParticipants || false,
        challengeVoucherConfiguration: voucherConfiguration ? {
            required: voucherConfiguration.required,
            url: voucherConfiguration.url,
            title: voucherConfiguration.title ? voucherConfiguration.title.reduce<{}>((result, value) => ({ ...result, [String(value && value.language)]: { text: value && value.text } }), {}) : {},
            description: voucherConfiguration.description ? voucherConfiguration.description.reduce<{}>((result, value) => ({ ...result, [String(value && value.language)]: { text: value && value.text } }), {}) : {}
        } : getEmptyChallengeVoucherConfig(),
        enabledLocales: enabledLocales ?? [],
        totals: {
            caloriesVisible: Boolean(totals && totals.caloriesVisible),
            creditsEarnedVisible: Boolean(totals && totals.creditsEarnedVisible),
            distanceVisible: Boolean(totals && totals.distanceVisible),
            durationVisible: Boolean(totals && totals.durationVisible),
            elevationVisible: Boolean(totals && totals.elevationVisible),
            stepsVisible: Boolean(totals && totals.stepsVisible)
        },
        data: extraData,
        relatedContentId: relatedContents?.map(content => content.id),
        superDays: mapSuperDaysToFormValues(superDates),
        autoParticipationEnabled: Boolean(autoParticipationEnabled),
        disableComments: Boolean(disableComments)
    };
}
