
import type { Klass, LexicalNode } from "lexical";

import { CodeHighlightNode, CodeNode } from "@lexical/code";
import { HashtagNode } from "@lexical/hashtag";
import { AutoLinkNode, LinkNode } from "@lexical/link";
import { ListNode } from "@lexical/list";
import { MarkNode } from "@lexical/mark";
import { OverflowNode } from "@lexical/overflow";
import { HorizontalRuleNode } from "@lexical/react/LexicalHorizontalRuleNode";
import { HeadingNode } from "@lexical/rich-text";
import { TableCellNode, TableNode, TableRowNode } from "@lexical/table";

import { EmojiNode } from "./EmojiNode";
import { ExtendedListItemNode } from "./ExtendedListNode";
import { ExtendedParagraphNode } from "./ExtendedParagraphNode";
import { ExtendedQuoteNode } from "./ExtendedQuoteNode";
import { ExtendedTextNode } from "./ExtendedTextNode";
import { ImageNode } from "./ImageNode";
import { KeywordNode } from "./KeywordNode";
import { YouTubeNode } from "./YouTubeNode";

const PlaygroundNodes: Array<Klass<LexicalNode>> = [
    HeadingNode,
    ListNode,
    CodeNode,
    TableNode,
    TableCellNode,
    TableRowNode,
    HashtagNode,
    CodeHighlightNode,
    AutoLinkNode,
    LinkNode,
    OverflowNode,
    ImageNode,
    EmojiNode,
    KeywordNode,
    HorizontalRuleNode,
    YouTubeNode,
    MarkNode,
    ExtendedTextNode,
    ExtendedQuoteNode,
    ExtendedParagraphNode,
    ExtendedListItemNode
];

export default PlaygroundNodes;
