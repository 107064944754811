import { TextByLanguage } from "@graphql2/types";

export function toTextByLanguage(langObject?: { [lang: string]: string; }): TextByLanguage[] {
    if (!langObject) {
        return [];
    }

    return Object.keys(langObject).map(key => ({
        language: key,
        text: langObject[key] || ""
    }));
}

export function toTextByLanguageLexicalAdjusted(langObject?: { [lang: string]: string; }): TextByLanguage[] {
    if (!langObject) {
        return [];
    }

    return Object.keys(langObject).map(key => ({

        language: key,
        text: fixLexicalEditorIssues(langObject[key])
    }));
}

function fixLexicalEditorIssues(html: string): string {
    if (!html) {
        return "";
    }

    const parser = new DOMParser();
    const doc = parser.parseFromString(html, "text/html");

    const whiteSpacePreserved = preserveWhitespace(doc);
    const strikeThroughFxied = fixStrikethroughElementHTML(whiteSpacePreserved);

    const output = strikeThroughFxied.body.textContent ? strikeThroughFxied.body.innerHTML : "";

    return output;
}

function preserveWhitespace(doc: Document): Document {
    function processNode(node) {
        if (node.nodeType === Node.TEXT_NODE) {
            // eslint-disable-next-line no-param-reassign
            node.textContent = node.textContent.replace(/\s+/g, match => match.split("").map(() => "\u2002").join(""));
        } else if (node.nodeType === Node.ELEMENT_NODE) {
            node.childNodes.forEach(processNode);
        }
    }

    processNode(doc.body);

    return doc;
}

const fixStrikethroughElementHTML = (doc: Document): Document => {
    const { body } = doc;

    const strikethroughElements = body.querySelectorAll(".PlaygroundEditorTheme__textStrikethrough");
    const underlineElements = body.querySelectorAll(".PlaygroundEditorTheme__textUnderline");
    const underlineStrikethroughElements = body.querySelectorAll(".PlaygroundEditorTheme__textUnderlineStrikethrough");

    const allElements = [...Array.from(strikethroughElements), ...Array.from(underlineElements), ...Array.from(underlineStrikethroughElements)];

    allElements.forEach((startElement: HTMLElement) => {
        const { fontSize } = startElement.style;
        let currentElement: HTMLElement | null = startElement;
        let sCount = 0;
        let uCount = 0;

        while (currentElement && currentElement.tagName !== "P") {
            if (currentElement.tagName === "S") {
                sCount += 1;
                if (sCount === 1) {
                    currentElement.style.fontSize = fontSize;
                } else if (sCount === 2) {
                    currentElement.style.textDecoration = "none";
                }
            }

            if (currentElement.tagName === "U") {
                uCount += 1;
                if (uCount === 1) {
                    currentElement.style.fontSize = fontSize;
                } else if (uCount === 2) {
                    currentElement.style.textDecoration = "none";
                }
            }

            currentElement = currentElement.parentElement;
        }
    });

    return doc;
};
