import { useDebounce } from "@hooks/useDebounce";
import React, { useEffect, useState } from "react";

interface HTMLRawEditorProps {
    initialValue: string;
    onBlur?(): void;
    onChange: (value: string) => void;
    disabled?: boolean;
}

const HTMLRawEditor = ({ onBlur, disabled, initialValue, onChange }: HTMLRawEditorProps) => {
    const [html, setHtml] = useState<string>(initialValue);
    const [error, setError] = useState<string | null>(null);
    const [deferredHtml, setDeferredHtml] = useState<string>("");

    useDebounce(() => {
        setDeferredHtml(html);
    }, 200);

    useEffect(() => {
        try {
            const parser = new DOMParser();
            const doc = parser.parseFromString(deferredHtml, "text/html");
            const errorNode = doc.querySelector("parsererror");

            if (errorNode) {
                throw new Error("HTML parsing error");
            }
            setError(null);
        } catch (err) {
            console.log({ err });
            setError((err as Error).message);
        }
    }, [deferredHtml]);

    useEffect(() => {
        if (!error && deferredHtml) {
            onChange(deferredHtml);
        }
    }, [deferredHtml, error]);

    return (
        <div style={containerStyle}>
            <textarea
                disabled={disabled}
                placeholder="Enter your HTML here..."
                style={textareaStyle}
                value={html}
                onBlur={onBlur}
                onChange={(e) => setHtml(e.target.value)}
            />
            <div style={previewContainerStyle}>
                {error ? (
                    <div style={errorStyle}>{error}</div>
                ) : (
                    <iframe
                        sandbox="allow-scripts"
                        srcDoc={deferredHtml}
                        style={iframeStyle}
                        title="HTML Preview"
                    />
                )}
            </div>
        </div>
    );
};

export default HTMLRawEditor;

const containerStyle: React.CSSProperties = {
    display: "flex",
    height: "100%",
    borderTop: "1px solid #d9d9d9"
};

const textareaStyle: React.CSSProperties = {
    width: "50%",
    padding: "16px",
    fontFamily: "monospace",
    fontSize: "14px",
    border: "none",
    outline: "none",
    borderRight: "1px solid #d9d9d9"
};

const previewContainerStyle: React.CSSProperties = {
    width: "50%",
    position: "relative"
};

const errorStyle: React.CSSProperties = {
    position: "absolute",
    inset: 0,
    display: "flex",
    justifyContent: "center",
    backgroundColor: "#FEE2E2",
    color: "#EF4444",
    padding: "16px",
    fontSize: "24px"
};

const iframeStyle: React.CSSProperties = {
    width: "100%",
    height: "100%"
};
