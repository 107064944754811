
import { FieldTimeOutlined } from "@ant-design/icons";
import { DynamicPreviewProps, PreviewTypes } from "@components/dashboard/dynamicPreview/dynamicPreviewContainer";
import { WidgetSort } from "@components/dashboard/widgets/widgetSort";
import { translateChallengeTitles } from "@utils/mappers/challengeMapper";
import { translateContentTitles } from "@utils/mappers/contentMapper";
import { translateEventTitles } from "@utils/mappers/eventMapper";
import { List, Spin } from "antd";
import { autobind } from "core-decorators";
import * as React from "react";
import { FormattedMessage } from "react-intl";

interface Item {
    title: string;
    isPublished?: boolean;
}

@autobind
export class DynamicPreviewComponent extends React.Component<DynamicPreviewProps, {}> {
    public render() {
        const { limit, titleId, paginationOptions, type } = this.props;

        return (
            <React.Fragment>
                <WidgetSort
                    paginationLimit={limit}
                    type={type}
                    {...paginationOptions}
                    defaults={this.sortOptions}
                />
                <FormattedMessage
                    id={titleId}
                    tagName="h3"
                />
                {this.loading
                    ? <Spin style={{ width: "100%", margin: "100px 0" }} />
                    : (
                        <List
                            dataSource={this.item as any}
                            itemLayout="horizontal"
                            renderItem={({ title, isPublished }: Item) => (
                                <List.Item>
                                    {type === PreviewTypes.content && !isPublished && <FieldTimeOutlined />}
                                    {title}
                                </List.Item>
                            )}
                        />
                    )}
            </React.Fragment>
        );
    }

    private get sortOptions() {
        const { paginationOptions: { defaults }, sort } = this.props;
        return {
            ...defaults,
            ...sort
        };
    }

    private get loading() {
        const { type, challengeTitles, eventTitles, contentTitles } = this.props;
        switch (type) {
            case PreviewTypes.challenge:
                return challengeTitles && challengeTitles.loading;
            case PreviewTypes.content:
                return contentTitles && contentTitles.loading;
            case PreviewTypes.event:
                return eventTitles && eventTitles.loading;
            default:
                return false;
        }
    }

    private get item() {
        const { type, contentTitles, eventTitles, challengeTitles } = this.props;

        switch (type) {
            case PreviewTypes.challenge:
                return translateChallengeTitles(challengeTitles?.challenges || []);
            case PreviewTypes.content:
                return translateContentTitles(contentTitles?.contents || []);
            case PreviewTypes.event:
                return translateEventTitles(eventTitles?.events || []);
            default:
                return [];
        }
    }
}
