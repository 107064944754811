
import "./Modal.css";

import * as React from "react";
import { ReactNode, useEffect, useRef } from "react";
import { createPortal } from "react-dom";

function PortalImpl({
    onClose,
    children,
    title,
    closeOnClickOutside
}: {
  children: ReactNode;
  closeOnClickOutside: boolean;
  onClose: () => void;
  title: string;
}) {
    const modalRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (modalRef.current !== null) {
            modalRef.current.focus();
        }
    }, []);

    useEffect(() => {
        let modalOverlayElement: HTMLElement | null = null;
        const handler = (event: KeyboardEvent) => {
            if (event.key === "Escape") {
                onClose();
            }
        };
        const clickOutsideHandler = (event: MouseEvent) => {
            const { target } = event;
            if (
                modalRef.current !== null
        && !modalRef.current.contains(target as Node)
        && closeOnClickOutside
            ) {
                onClose();
            }
        };
        const modelElement = modalRef.current;
        if (modelElement !== null) {
            modalOverlayElement = modelElement.parentElement;
            if (modalOverlayElement !== null) {
                modalOverlayElement.addEventListener("click", clickOutsideHandler);
            }
        }

        window.addEventListener("keydown", handler);

        return () => {
            window.removeEventListener("keydown", handler);
            if (modalOverlayElement !== null) {
                modalOverlayElement?.removeEventListener("click", clickOutsideHandler);
            }
        };
    }, [closeOnClickOutside, onClose]);

    return (
        <div
            className="Modal__overlay"
            role="dialog"
        >
            <div
                ref={modalRef}
                className="Modal__modal"
                tabIndex={-1}
            >
                <h2 className="Modal__title">{title}</h2>
                <button
                    aria-label="Close modal"
                    className="Modal__closeButton"
                    type="button"
                    onClick={onClose}
                >
          X
                </button>
                <div className="Modal__content">{children}</div>
            </div>
        </div>
    );
}

export default function Modal({
    onClose,
    children,
    title,
    closeOnClickOutside = false
}: {
  children: ReactNode;
  closeOnClickOutside?: boolean;
  onClose: () => void;
  title: string;
}): JSX.Element {
    return createPortal(
        <PortalImpl
            closeOnClickOutside={closeOnClickOutside}
            title={title}
            onClose={onClose}
        >
            {children}
        </PortalImpl>,
        document.body
    );
}
