
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import {
    LexicalTypeaheadMenuPlugin,
    MenuOption,
    useBasicTypeaheadTriggerMatch
} from "@lexical/react/LexicalTypeaheadMenuPlugin";
import {
    $createTextNode,
    $getSelection,
    $isRangeSelection,
    TextNode
} from "lexical";
import * as React from "react";
import { useCallback, useEffect, useMemo, useState } from "react";
import * as ReactDOM from "react-dom";

class EmojiOption extends MenuOption {
  public title: string;

  public emoji: string;

  public keywords: Array<string>;

  public constructor(
      title: string,
      emoji: string,
      options: {
      keywords?: Array<string>;
    }
  ) {
      super(title);
      this.title = title;
      this.emoji = emoji;
      this.keywords = options.keywords || [];
  }
}
function EmojiMenuItem({
    index,
    isSelected,
    onClick,
    onMouseEnter,
    option
}: {
  index: number;
  isSelected: boolean;
  onClick: () => void;
  onMouseEnter: () => void;
  option: EmojiOption;
}) {
    let className = "item";
    if (isSelected) {
        className += " selected";
    }
    return (
        <li
            key={option.key}
            ref={option.setRefElement}
            aria-selected={isSelected}
            className={className}
            id={`typeahead-item-${index}`}
            role="option"
            tabIndex={-1}
            onClick={onClick}
            onMouseEnter={onMouseEnter}
        >
            <span className="text">
                {option.emoji}
                {" "}
                {option.title}
            </span>
        </li>
    );
}

type Emoji = {
  emoji: string;
  description: string;
  category: string;
  aliases: Array<string>;
  tags: Array<string>;
  unicode_version: string;
  ios_version: string;
  skin_tones?: boolean;
};

const MAX_EMOJI_SUGGESTION_COUNT = 10;

export default function EmojiPickerPlugin() {
    const [editor] = useLexicalComposerContext();
    const [queryString, setQueryString] = useState<string | null>(null);
    const [emojis, setEmojis] = useState<Array<Emoji>>([]);

    useEffect(() => {
        import("../../utils/emoji-list").then((file) => setEmojis(file.default));
    }, []);

    const emojiOptions = useMemo(
        () => (emojis != null
            ? emojis.map(
                ({ emoji, aliases, tags }) => new EmojiOption(aliases[0], emoji, {
                    keywords: [...aliases, ...tags]
                })
            )
            : []),
        [emojis]
    );

    const checkForTriggerMatch = useBasicTypeaheadTriggerMatch(":", {
        minLength: 0
    });

    const options: Array<EmojiOption> = useMemo(() => {
        return emojiOptions
            .filter((option: EmojiOption) => {
                if (queryString === null) {
                    return emojiOptions;
                }

                if (new RegExp(queryString, "gi").exec(option.title) || option.keywords != null) {
                    return option.keywords.some((keyword: string) => new RegExp(queryString, "gi").exec(keyword));
                }

                return false;
            })
            .slice(0, MAX_EMOJI_SUGGESTION_COUNT);
    }, [emojiOptions, queryString]);

    const onSelectOption = useCallback(
        (
            selectedOption: EmojiOption,
            nodeToRemove: TextNode | null,
            closeMenu: () => void
        ) => {
            editor.update(() => {
                const selection = $getSelection();

                if (!$isRangeSelection(selection) || selectedOption == null) {
                    return;
                }

                if (nodeToRemove) {
                    nodeToRemove.remove();
                }

                selection.insertNodes([$createTextNode(selectedOption.emoji)]);

                closeMenu();
            });
        },
        [editor]
    );

    return (
        <LexicalTypeaheadMenuPlugin
            menuRenderFn={(
                anchorElementRef,
                { selectedIndex, selectOptionAndCleanUp, setHighlightedIndex }
            ) => {
                if (anchorElementRef.current == null || options.length === 0) {
                    return null;
                }

                return anchorElementRef.current && options.length
                    ? ReactDOM.createPortal(
                        <div className="typeahead-popover emoji-menu">
                            <ul>
                                {options.map((option: EmojiOption, index) => (
                                    <EmojiMenuItem
                                        key={option.key}
                                        index={index}
                                        isSelected={selectedIndex === index}
                                        option={option}
                                        onClick={() => {
                                            setHighlightedIndex(index);
                                            selectOptionAndCleanUp(option);
                                        }}
                                        onMouseEnter={() => {
                                            setHighlightedIndex(index);
                                        }}
                                    />
                                ))}
                            </ul>
                        </div>,
                        anchorElementRef.current
                    )
                    : null;
            }}
            options={options}
            triggerFn={checkForTriggerMatch}
            onQueryChange={setQueryString}
            onSelectOption={onSelectOption}
        />
    );
}
