import { invariant } from "@apollo/client/utilities/globals";
import {
    $isTextNode,
    DOMConversion,
    DOMConversionMap,
    DOMConversionOutput,
    DOMExportOutput,
    EditorConfig,
    isHTMLElement,
    LexicalEditor,
    LexicalNode,
    SerializedTextNode,
    TextNode
} from "lexical";

export class ExtendedTextNode extends TextNode {
    public static getType(): string {
        return "extended-text";
    }

    public static clone(node: ExtendedTextNode): ExtendedTextNode {
        return new ExtendedTextNode(node.__text, node.__key);
    }

    public createDOM(config: EditorConfig, editor?: LexicalEditor): HTMLElement {
        const dom = super.createDOM(config, editor);

        if (this.hasFormat("code")) {
            dom.style.fontFamily = "Menlo, Consolas, Monaco, monospace";
            dom.style.padding = "1px 0.25rem";
            dom.style.backgroundColor = "rgb(240, 242, 245)";
            dom.style.fontSize = "94%";
            dom.style.whiteSpace = "normal !important";
        }

        return dom;
    }

    public exportDOM(editor: LexicalEditor): DOMExportOutput {
        let { element } = super.exportDOM(editor);
        invariant(
            element !== null && isHTMLElement(element),
            "Expected TextNode createDOM to always return a HTMLElement"
        );

        element.style.whiteSpace = "normal";

        if (this.hasFormat("bold")) {
            element = wrapElementWith(element, "b");
        }
        if (this.hasFormat("italic")) {
            element = wrapElementWith(element, "i");
        }
        if (this.hasFormat("strikethrough")) {
            element = wrapElementWith(element, "s");
        }
        if (this.hasFormat("underline")) {
            element = wrapElementWith(element, "u");
        }

        return {
            element
        };
    }

    public static importDOM(): DOMConversionMap | null {
        const importers = TextNode.importDOM();

        return {
            ...importers,
            code: () => ({
                conversion: patchStyleConversion(importers?.code),
                priority: 1
            }),
            em: () => ({
                conversion: patchStyleConversion(importers?.em),
                priority: 1
            }),
            span: () => ({
                conversion: patchStyleConversion(importers?.span),
                priority: 1
            }),
            strong: () => ({
                conversion: patchStyleConversion(importers?.strong),
                priority: 1
            }),
            sub: () => ({
                conversion: patchStyleConversion(importers?.sub),
                priority: 1
            }),
            sup: () => ({
                conversion: patchStyleConversion(importers?.sup),
                priority: 1
            })
        };
    }

    public static importJSON(serializedNode: SerializedTextNode): TextNode {
        return TextNode.importJSON(serializedNode);
    }

    public isSimpleText() {
        return (
            (this.__type === "text" || this.__type === "extended-text")
      && this.__mode === 0
        );
    }

    public exportJSON(): SerializedTextNode {
        return {
            ...super.exportJSON(),
            type: "extended-text",
            version: 1
        };
    }
}

export function $createExtendedTextNode(text: string): ExtendedTextNode {
    return new ExtendedTextNode(text);
}

export function $isExtendedTextNode(
    node: LexicalNode | null | undefined
): node is ExtendedTextNode {
    return node instanceof ExtendedTextNode;
}

function patchStyleConversion(
    originalDOMConverter?: (node: HTMLElement) => DOMConversion | null
): (node: HTMLElement) => DOMConversionOutput | null {
    return (node) => {
        const original = originalDOMConverter?.(node);
        if (!original) {
            return null;
        }
        const originalOutput = original.conversion(node);

        if (!originalOutput) {
            return originalOutput;
        }

        const { backgroundColor } = node.style;
        const { color } = node.style;
        const { fontFamily } = node.style;
        const { fontWeight } = node.style;
        const { fontSize } = node.style;
        const { textDecoration } = node.style;
        const { padding } = node.style;

        return {
            ...originalOutput,
            forChild: (lexicalNode, parent) => {
                const originalForChild = originalOutput?.forChild ?? ((x) => x);
                const result = originalForChild(lexicalNode, parent);

                if ($isTextNode(result)) {
                    const style = [
                        backgroundColor ? `background-color: ${backgroundColor}` : null,
                        color ? `color: ${color}` : null,
                        fontFamily ? `font-family: ${fontFamily}` : null,
                        fontWeight ? `font-weight: ${fontWeight}` : null,
                        fontSize ? `font-size: ${fontSize}` : null,
                        textDecoration ? `text-decoration: ${textDecoration}` : null,
                        padding ? `padding: ${padding}` : null
                    ]
                        .filter((value) => value != null)
                        .join("; ");
                    if (style.length) {
                        return result.setStyle(style);
                    }
                }
                return result;
            }
        };
    };
}

function wrapElementWith(
    element: HTMLElement | Text,
    tag: string
): HTMLElement {
    const el = document.createElement(tag);
    el.appendChild(element);
    return el;
}

