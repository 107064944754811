
import type { EditorThemeClasses } from "lexical";

import "./PlaygroundEditorTheme.css";

const theme: EditorThemeClasses = {
    hr: "PlaygroundEditorTheme__hr",
    image: "editor-image",
    text: {
        strikethrough: "PlaygroundEditorTheme__textStrikethrough",
        underline: "PlaygroundEditorTheme__textUnderline",
        underlineStrikethrough: "PlaygroundEditorTheme__textUnderlineStrikethrough"
    },
    paragraph: "PlaygroundEditorTheme__paragraph",
    heading: {
        h1: "PlaygroundEditorTheme__heading1",
        h2: "PlaygroundEditorTheme__heading2",
        h3: "PlaygroundEditorTheme__heading3"
    }
};

export default theme;
