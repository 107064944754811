
export const Event_Super_admin = "00";
export const User_admin = "01";
export const User_Super_admin = "02";
export const Event_admin = "03";
export const Challenge_admin = "04";
export const Global_goal_admin = "05";
export const Content_admin = "06";
export const Social_admin = "07";
export const Gallery_admin = "08";
export const Journey_admin = "09";
export const Checkup_location_admin = "10";
export const Voucher_admin = "11";
export const Article_admin = "12";
export const Comment_admin = "13";
export const Like_admin = "14";
export const Translation_admin = "15";
export const Translation_super_admin = "16";
export const Dashboard_admin = "17";
export const Payment_admin = "18";
export const Ambassador_admin = "19";
export const Credits_admin = "24";
export const Project_admin = "25";
export const Flow_admin = "26";
export const Editor_admin = "27";

export const allRoles = [
    Event_Super_admin,
    User_admin,
    User_Super_admin,
    Event_admin,
    Challenge_admin,
    Global_goal_admin,
    Content_admin,
    Social_admin,
    Gallery_admin,
    Journey_admin,
    Checkup_location_admin,
    Voucher_admin,
    Translation_admin,
    Translation_super_admin,
    Credits_admin,
    Project_admin,
    Flow_admin,
    Editor_admin
];
