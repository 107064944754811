
import "./ContentEditable.css";

import { ContentEditable } from "@lexical/react/LexicalContentEditable";
import * as React from "react";

type Props = {
  className?: string;
  placeholderClassName?: string;
  placeholder: string;
  onBlur?(): void;
  disabled?: boolean;
};

export default function LexicalContentEditable({
    className,
    placeholder,
    placeholderClassName,
    onBlur,
    disabled
}: Props): JSX.Element {
    return (
        <ContentEditable
            aria-placeholder={placeholder}
            className={className ?? "ContentEditable__root"}
            disabled={disabled}
            placeholder={(
                <div className={placeholderClassName ?? "ContentEditable__placeholder"}>
                    {placeholder}
                </div>
            )}
            onBlur={onBlur}
        />
    );
}
