
import type { EditorConfig, LexicalNode, SerializedTextNode } from "lexical";

import { TextNode } from "lexical";

export type SerializedKeywordNode = SerializedTextNode;

export class KeywordNode extends TextNode {
    public static getType(): string {
        return "keyword";
    }

    public static clone(node: KeywordNode): KeywordNode {
        return new KeywordNode(node.__text, node.__key);
    }

    public static importJSON(serializedNode: SerializedKeywordNode): KeywordNode {
        const node = $createKeywordNode(serializedNode.text);
        node.setFormat(serializedNode.format);
        node.setDetail(serializedNode.detail);
        node.setMode(serializedNode.mode);
        node.setStyle(serializedNode.style);
        return node;
    }

    public exportJSON(): SerializedKeywordNode {
        return {
            ...super.exportJSON(),
            type: "keyword",
            version: 1
        };
    }

    public createDOM(config: EditorConfig): HTMLElement {
        const dom = super.createDOM(config);
        dom.style.cursor = "default";
        dom.className = "keyword";
        return dom;
    }

    public canInsertTextBefore(): boolean {
        return false;
    }

    public canInsertTextAfter(): boolean {
        return false;
    }

    public isTextEntity(): true {
        return true;
    }
}

export function $createKeywordNode(keyword: string): KeywordNode {
    return new KeywordNode(keyword);
}

export function $isKeywordNode(node: LexicalNode | null | undefined): boolean {
    return node instanceof KeywordNode;
}
