/* eslint-disable jsx-a11y/control-has-associated-label */

import "./fontSize.css";

import { $patchStyleText } from "@lexical/selection";
import { $getSelection, LexicalEditor } from "lexical";
import * as React from "react";

const MIN_ALLOWED_FONT_SIZE = 8;
const MAX_ALLOWED_FONT_SIZE = 72;
const DEFAULT_FONT_SIZE = 15;

// eslint-disable-next-line no-shadow
enum updateFontSizeType {
  increment = 1,
  decrement,
}

export default function FontSize({
    selectionFontSize,
    disabled,
    editor
}: {
  selectionFontSize: string;
  disabled: boolean;
  editor: LexicalEditor;
}) {
    const [inputValue, setInputValue] = React.useState<string>(selectionFontSize);
    const [inputChangeFlag, setInputChangeFlag] = React.useState<boolean>(false);

    /**
   * Calculates the new font size based on the update type.
   * @param currentFontSize - The current font size
   * @param updateType - The type of change, either increment or decrement
   * @returns the next font size
   */
    const calculateNextFontSize = (
        currentFontSize: number,
        updateType: updateFontSizeType | null
    ) => {
        if (!updateType) {
            return currentFontSize;
        }

        let updatedFontSize: number = currentFontSize;
        switch (updateType) {
            case updateFontSizeType.decrement:
                switch (true) {
                    case currentFontSize > MAX_ALLOWED_FONT_SIZE:
                        updatedFontSize = MAX_ALLOWED_FONT_SIZE;
                        break;
                    case currentFontSize >= 48:
                        updatedFontSize -= 12;
                        break;
                    case currentFontSize >= 24:
                        updatedFontSize -= 4;
                        break;
                    case currentFontSize >= 14:
                        updatedFontSize -= 2;
                        break;
                    case currentFontSize >= 9:
                        updatedFontSize -= 1;
                        break;
                    default:
                        updatedFontSize = MIN_ALLOWED_FONT_SIZE;
                        break;
                }
                break;

            case updateFontSizeType.increment:
                switch (true) {
                    case currentFontSize < MIN_ALLOWED_FONT_SIZE:
                        updatedFontSize = MIN_ALLOWED_FONT_SIZE;
                        break;
                    case currentFontSize < 12:
                        updatedFontSize += 1;
                        break;
                    case currentFontSize < 20:
                        updatedFontSize += 2;
                        break;
                    case currentFontSize < 36:
                        updatedFontSize += 4;
                        break;
                    case currentFontSize <= 60:
                        updatedFontSize += 12;
                        break;
                    default:
                        updatedFontSize = MAX_ALLOWED_FONT_SIZE;
                        break;
                }
                break;

            default:
                break;
        }
        return updatedFontSize;
    };
    /**
   * Patches the selection with the updated font size.
   */

    const updateFontSizeInSelection = React.useCallback(
        (newFontSize: string | null, updateType: updateFontSizeType | null) => {
            const getNextFontSize = (prevFontSize: string | null): string => {
                let prevFontSizeCopy = prevFontSize ?? `${DEFAULT_FONT_SIZE}px`;
                prevFontSizeCopy = prevFontSizeCopy.slice(0, -2);

                const nextFontSize = calculateNextFontSize(
                    Number(prevFontSizeCopy),
                    updateType
                );
                return `${nextFontSize}px`;
            };

            editor.update(() => {
                if (editor.isEditable()) {
                    const selection = $getSelection();
                    if (selection !== null) {
                        $patchStyleText(selection, {
                            "font-size": newFontSize || getNextFontSize
                        });
                    }
                }
            });
        },
        [editor]
    );

    const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
        const inputValueNumber = Number(inputValue);

        if (["e", "E", "+", "-"].includes(e.key) || isNaN(inputValueNumber)) {
            e.preventDefault();
            setInputValue("");
            return;
        }
        setInputChangeFlag(true);
        if (e.key === "Enter" || e.key === "Tab" || e.key === "Escape") {
            e.preventDefault();

            updateFontSizeByInputValue(inputValueNumber);
        }
    };

    const handleInputBlur = () => {
        if (inputValue !== "" && inputChangeFlag) {
            const inputValueNumber = Number(inputValue);
            updateFontSizeByInputValue(inputValueNumber);
        }
    };

    const handleButtonClick = (updateType: updateFontSizeType) => {
        if (inputValue !== "") {
            const nextFontSize = calculateNextFontSize(
                Number(inputValue),
                updateType
            );
            updateFontSizeInSelection(`${String(nextFontSize)}px`, null);
        } else {
            updateFontSizeInSelection(null, updateType);
        }
    };

    const updateFontSizeByInputValue = (inputValueNumber: number) => {
        let updatedFontSize = inputValueNumber;
        if (inputValueNumber > MAX_ALLOWED_FONT_SIZE) {
            updatedFontSize = MAX_ALLOWED_FONT_SIZE;
        } else if (inputValueNumber < MIN_ALLOWED_FONT_SIZE) {
            updatedFontSize = MIN_ALLOWED_FONT_SIZE;
        }

        setInputValue(String(updatedFontSize));
        updateFontSizeInSelection(`${String(updatedFontSize)}px`, null);
        setInputChangeFlag(false);
    };

    React.useEffect(() => {
        setInputValue(selectionFontSize);
    }, [selectionFontSize]);

    const isFontDecrementDisabled = disabled || (selectionFontSize !== "" && Number(inputValue) <= MIN_ALLOWED_FONT_SIZE);
    const isFontIncrementDisabled = disabled || (selectionFontSize !== "" && Number(inputValue) >= MAX_ALLOWED_FONT_SIZE);

    return (
        <React.Fragment>
            <button
                className="toolbar-item font-decrement"
                disabled={isFontDecrementDisabled}
                type="button"
                onClick={() => handleButtonClick(updateFontSizeType.decrement)}
            >
                <i className="format minus-icon" />
            </button>

            <input
                className="toolbar-item font-size-input"
                disabled={disabled}
                max={MAX_ALLOWED_FONT_SIZE}
                min={MIN_ALLOWED_FONT_SIZE}
                type="number"
                value={inputValue}
                onBlur={handleInputBlur}
                onChange={(e) => setInputValue(e.target.value)}
                onKeyDown={handleKeyPress}
            />

            <button
                className="toolbar-item font-increment"
                disabled={isFontIncrementDisabled}
                type="button"
                onClick={() => handleButtonClick(updateFontSizeType.increment)}
            >
                <i className="format add-icon" />
            </button>
        </React.Fragment>
    );
}
