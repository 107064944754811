import { AmbassadorConfigAmbassadorsTableData } from "@components/ambassador/ambassadorConfigAmbassadorsTable";
import { AmbassadorTableData } from "@components/ambassador/ambassadorTable";
import { AddAmbassadorConfigMutationVariables, AddAmbassadorMutationVariables, AmbassadorConfigFragment, AmbassadorFragment } from "@graphql2/types";
import { AmbassadorConfigFormValues } from "@pages/addAmbassadorConfigPage/addAmbassadorConfigPage";
import { AmbassadorsFormValues } from "@pages/addAmbassadorsPage/addAmbassadorsPage";
import { emptyAmbassador } from "@utils/emptyItems/emptyAmbassador";
import { getText } from "@utils/getText";
import { toLanguageObject } from "@utils/toLanguageObject";
import { toTextByLanguage, toTextByLanguageLexicalAdjusted } from "@utils/toTextByLanguage";

export const translateAmbassadorConfigs = (originals: AmbassadorConfigFragment[]): AmbassadorTableData[] => {
    return originals.map(ambassadorConfig => {
        const { id, place, sequence } = ambassadorConfig;

        return {
            id,
            place,
            sequence
        };
    });
};

export const translateAmbassadors = (originals: AmbassadorFragment[]): AmbassadorConfigAmbassadorsTableData[] => {
    return originals.map(ambassador => {
        const { id, title, createdOn, activityTypes, place } = ambassador;

        return {
            id,
            name: title ? getText(title) : "",
            createdOn: createdOn || 0,
            activities: activityTypes || [],
            place
        };
    });
};

export const ambassadorConfigToFormValues = (ambassadorConfig: AmbassadorConfigFragment): AmbassadorConfigFormValues => {
    const { visibility, image, title, intro, ambassadorIntro, caption } = ambassadorConfig;

    return {
        ...ambassadorConfig,
        visibility: {
            info: (visibility && visibility.info) || false,
            name: (visibility && visibility.name) || false,
            icons: (visibility && visibility.icons) || false
        },
        image: {
            shape: (image && image.shape) || "round",
            size: (image && image.size) || "small"
        },
        title: toLanguageObject(title),
        intro: toLanguageObject(intro),
        ambassadorIntro: toLanguageObject(ambassadorIntro),
        caption: toLanguageObject(caption)
    };
};

export const formValuesToAmbassadorConfig = (formValues: AmbassadorConfigFormValues): AddAmbassadorConfigMutationVariables => {
    const { title, intro, ambassadorIntro, caption } = formValues;

    return {
        ...formValues,
        title: toTextByLanguage(title),
        intro: toTextByLanguage(intro),
        ambassadorIntro: toTextByLanguage(ambassadorIntro),
        caption: toTextByLanguage(caption)
    };
};

export const formValuesToAmbassador = (formValues: AmbassadorsFormValues): AddAmbassadorMutationVariables => {
    const {
        title,
        info,
        description,
        imageUrl,
        tags,
        activityTypes,
        userId,
        place
    } = formValues;

    return {
        title: toTextByLanguage(title),
        info: toTextByLanguageLexicalAdjusted(info),
        description: toTextByLanguageLexicalAdjusted(description),
        image: imageUrl,
        tags,
        activityTypes,
        userId: userId || "",
        place
    };
};

export const ambassadorToFormValues = (ambassador?: AmbassadorFragment | null): AmbassadorsFormValues => {
    if (!ambassador) {
        return emptyAmbassador;
    }

    const {
        id,
        title,
        info,
        description,
        image,
        userId,
        activityTypes,
        tags,
        place
    } = ambassador;

    return {
        id,
        title: toLanguageObject(title),
        info: toLanguageObject(info),
        description: toLanguageObject(description),
        imageUrl: image || "",
        userId: userId || undefined,
        place,
        activityTypes: activityTypes && activityTypes.length ? activityTypes : [],
        tags: tags ? tags.map(tag => tag.id) : []
    };
};
