
import {
    INSERT_ORDERED_LIST_COMMAND,
    INSERT_UNORDERED_LIST_COMMAND
} from "@lexical/list";
import { INSERT_EMBED_COMMAND } from "@lexical/react/LexicalAutoEmbedPlugin";
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { INSERT_HORIZONTAL_RULE_COMMAND } from "@lexical/react/LexicalHorizontalRuleNode";
import {
    LexicalTypeaheadMenuPlugin,
    MenuOption,
    useBasicTypeaheadTriggerMatch
} from "@lexical/react/LexicalTypeaheadMenuPlugin";
import { $createHeadingNode, $createQuoteNode } from "@lexical/rich-text";
import { $setBlocksType } from "@lexical/selection";
import { INSERT_TABLE_COMMAND } from "@lexical/table";
import {
    $getSelection,
    $isRangeSelection,
    LexicalEditor,
    TextNode
} from "lexical";
import * as React from "react";
import { useCallback, useMemo, useState } from "react";
import * as ReactDOM from "react-dom";

import useModal from "../../hooks/useModal";
import { EmbedConfigs } from "../AutoEmbedPlugin";
import { InsertImageDialog } from "../ImagesPlugin";

class ComponentPickerOption extends MenuOption {
  // What shows up in the editor
  public title: string;

  // Icon for display
  public icon?: JSX.Element;

  // For extra searching.
  public keywords: Array<string>;

  // TBD
  public keyboardShortcut?: string;

  // What happens when you select this option?
  public onSelect: (queryString: string) => void;

  public constructor(
      title: string,
      options: {
      icon?: JSX.Element;
      keywords?: Array<string>;
      keyboardShortcut?: string;
      onSelect: (queryString: string) => void;
    }
  ) {
      super(title);
      this.title = title;
      this.keywords = options.keywords || [];
      this.icon = options.icon;
      this.keyboardShortcut = options.keyboardShortcut;
      this.onSelect = options.onSelect.bind(this);
  }
}

function ComponentPickerMenuItem({
    index,
    isSelected,
    onClick,
    onMouseEnter,
    option
}: {
  index: number;
  isSelected: boolean;
  onClick: () => void;
  onMouseEnter: () => void;
  option: ComponentPickerOption;
}) {
    let className = "item";
    if (isSelected) {
        className += " selected";
    }
    return (
        <li
            key={option.key}
            ref={option.setRefElement}
            aria-selected={isSelected}
            className={className}
            id={`typeahead-item-${index}`}
            role="option"
            tabIndex={-1}
            onClick={onClick}
            onMouseEnter={onMouseEnter}
        >
            {option.icon}
            <span className="text">{option.title}</span>
        </li>
    );
}

function getDynamicOptions(editor: LexicalEditor, queryString: string) {
    const options: Array<ComponentPickerOption> = [];

    if (queryString == null) {
        return options;
    }

    const tableMatch = queryString.match(/^([1-9]\d?)(?:x([1-9]\d?)?)?$/);

    if (tableMatch !== null) {
        const rows = tableMatch[1];
        const colOptions = tableMatch[2]
            ? [tableMatch[2]]
            : [1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map(String);

        options.push(
            ...colOptions.map(
                (columns) => new ComponentPickerOption(`${rows}x${columns} Table`, {
                    icon: <i className="icon table" />,
                    keywords: ["table"],
                    onSelect: () => editor.dispatchCommand(INSERT_TABLE_COMMAND, { columns, rows })
                })
            )
        );
    }

    return options;
}

type ShowModal = ReturnType<typeof useModal>[1];

function getBaseOptions(editor: LexicalEditor, showModal: ShowModal) {
    return [
        ...([1, 2, 3] as const).map(
            (n) => new ComponentPickerOption(`Heading ${n}`, {
                icon: <i className={`icon h${n}`} />,
                keywords: ["heading", "header", `h${n}`],
                onSelect: () => editor.update(() => {
                    const selection = $getSelection();
                    if ($isRangeSelection(selection)) {
                        $setBlocksType(selection, () => $createHeadingNode(`h${n}`));
                    }
                })
            })
        ),
        new ComponentPickerOption("Numbered List", {
            icon: <i className="icon number" />,
            keywords: ["numbered list", "ordered list", "ol"],
            onSelect: () => editor.dispatchCommand(INSERT_ORDERED_LIST_COMMAND, undefined)
        }),
        new ComponentPickerOption("Bulleted List", {
            icon: <i className="icon bullet" />,
            keywords: ["bulleted list", "unordered list", "ul"],
            onSelect: () => editor.dispatchCommand(INSERT_UNORDERED_LIST_COMMAND, undefined)
        }),
        new ComponentPickerOption("Quote", {
            icon: <i className="icon quote" />,
            keywords: ["block quote"],
            onSelect: () => editor.update(() => {
                const selection = $getSelection();
                if ($isRangeSelection(selection)) {
                    $setBlocksType(selection, () => $createQuoteNode());
                }
            })
        }),
        new ComponentPickerOption("Divider", {
            icon: <i className="icon horizontal-rule" />,
            keywords: ["horizontal rule", "divider", "hr"],
            onSelect: () => editor.dispatchCommand(INSERT_HORIZONTAL_RULE_COMMAND, undefined)
        }),
        ...EmbedConfigs.map(
            (embedConfig) => new ComponentPickerOption(`Embed ${embedConfig.contentName}`, {
                icon: embedConfig.icon,
                keywords: [...embedConfig.keywords, "embed"],
                onSelect: () => editor.dispatchCommand(INSERT_EMBED_COMMAND, embedConfig.type)
            })
        ),
        new ComponentPickerOption("Image", {
            icon: <i className="icon image" />,
            keywords: ["image", "photo", "picture", "file"],
            onSelect: () => showModal("Insert Image", (onClose) => (
                <InsertImageDialog
                    activeEditor={editor}
                    onClose={onClose}
                />
            ))
        })
    ];
}

export default function ComponentPickerMenuPlugin(): JSX.Element {
    const [editor] = useLexicalComposerContext();
    const [modal, showModal] = useModal();
    const [queryString, setQueryString] = useState<string | null>(null);

    const checkForTriggerMatch = useBasicTypeaheadTriggerMatch("/", {
        minLength: 0
    });

    const options = useMemo(() => {
        const baseOptions = getBaseOptions(editor, showModal);

        if (!queryString) {
            return baseOptions;
        }

        const regex = new RegExp(queryString, "i");

        return [
            ...getDynamicOptions(editor, queryString),
            ...baseOptions.filter(
                (option) => regex.test(option.title)
          || option.keywords.some((keyword) => regex.test(keyword))
            )
        ];
    }, [editor, queryString, showModal]);

    const onSelectOption = useCallback(
        (
            selectedOption: ComponentPickerOption,
            nodeToRemove: TextNode | null,
            closeMenu: () => void,
            matchingString: string
        ) => {
            editor.update(() => {
                nodeToRemove?.remove();
                selectedOption.onSelect(matchingString);
                closeMenu();
            });
        },
        [editor]
    );

    return (
        <React.Fragment>
            {modal}
            <LexicalTypeaheadMenuPlugin<ComponentPickerOption>
                menuRenderFn={(
                    anchorElementRef,
                    { selectedIndex, selectOptionAndCleanUp, setHighlightedIndex }
                ) => (anchorElementRef.current && options.length
                    ? ReactDOM.createPortal(
                        <div className="typeahead-popover component-picker-menu">
                            <ul>
                                {options.map((option, i: number) => (
                                    <ComponentPickerMenuItem
                                        key={option.key}
                                        index={i}
                                        isSelected={selectedIndex === i}
                                        option={option}
                                        onClick={() => {
                                            setHighlightedIndex(i);
                                            selectOptionAndCleanUp(option);
                                        }}
                                        onMouseEnter={() => {
                                            setHighlightedIndex(i);
                                        }}
                                    />
                                ))}
                            </ul>
                        </div>,
                        anchorElementRef.current
                    )
                    : null)}
                options={options}
                triggerFn={checkForTriggerMatch}
                onQueryChange={setQueryString}
                onSelectOption={onSelectOption}
            />
        </React.Fragment>
    );
}
