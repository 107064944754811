import { QuoteNode } from "@lexical/rich-text";

export class ExtendedQuoteNode extends QuoteNode {
    public static getType(): string {
        return "extended-quote";
    }

    public static clone(node: ExtendedQuoteNode): ExtendedQuoteNode {
        return new ExtendedQuoteNode(node.__key);
    }

    public createDOM(): HTMLElement {
        const element = document.createElement("blockquote");
        element.style.margin = "0";
        element.style.marginLeft = "20px";
        element.style.marginBottom = "10px";
        element.style.fontSize = "14px";
        element.style.color = "rgb(101, 103, 107)";
        element.style.borderLeftColor = "rgb(206, 208, 212)";
        element.style.borderLeftWidth = "4px";
        element.style.borderLeftStyle = "solid";
        element.style.paddingLeft = "16px";
        return element;
    }
}
