import { $generateHtmlFromNodes } from "@lexical/html";
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { EditorState } from "lexical";
import { useCallback, useEffect, useRef } from "react";

interface OnChangePluginProps {
  onChange: (html: string) => void;
  debounceTime?: number;
}

const DebouncedLexicalOnChangePlugin: React.FC<OnChangePluginProps> = ({
    onChange,
    debounceTime = 200
}) => {
    const [editor] = useLexicalComposerContext();
    const timerRef = useRef<NodeJS.Timer | null>(null);

    const debouncedOnChange = useCallback((editorState: EditorState) => {
        if (timerRef.current) {
            clearTimeout(timerRef.current);
        }

        timerRef.current = setTimeout(() => {
            editorState.read(() => {
                const html = $generateHtmlFromNodes(editor, null);
                onChange(html);
            });
        }, debounceTime);
    }, [onChange, debounceTime]);

    useEffect(() => {
        return editor.registerUpdateListener(({ editorState }) => {
            debouncedOnChange(editorState);
        });
    }, [editor, debouncedOnChange]);

    useEffect(() => {
        return () => {
            if (timerRef.current) {
                clearTimeout(timerRef.current);
            }
        };
    }, []);

    return null;
};

export default DebouncedLexicalOnChangePlugin;
