// https://lexical.dev/docs/concepts/node-replacement

import { EditorConfig, ParagraphNode, SerializedParagraphNode, Spread } from "lexical";

type SerializedExtendedParagraphNode = Spread<
  {
    __style: string;
  },
  SerializedParagraphNode
>;

export class ExtendedParagraphNode extends ParagraphNode {
    public static getType() {
        return "extended-paragraph";
    }

    public static clone(node: ExtendedParagraphNode) {
        return new ExtendedParagraphNode(node.__key);
    }

    public static importDOM() {
        return {
            p: (node: HTMLElement) => ({
                conversion: () => {
                    const paragraphNode = new ExtendedParagraphNode();
                    paragraphNode.__style = node.getAttribute("style") || "";
                    return { node: paragraphNode };
                },
                priority: 1 as const
            })
        };
    }

    public createDOM(config: EditorConfig) {
        const dom = super.createDOM(config);

        if (this.__style) {
            dom.setAttribute("style", this.__style);
        }

        return dom;
    }

    public exportJSON(): SerializedExtendedParagraphNode {
        return {
            ...super.exportJSON(),
            __style: this.__style,
            type: "extended-paragraph",
            version: 1
        };
    }
}
