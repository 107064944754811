import { InboxOutlined } from "@ant-design/icons";
import { cloudinaryUpload } from "@utils/cloudinary";
import { toBase64 } from "@utils/convertToBase64";
import { Button, Spin, Upload } from "antd";
import { FormInstance } from "antd/lib/form";
import { useForm } from "antd/lib/form/Form";
import { NamePath } from "antd/lib/form/interface";
import * as environment from "environment";
import * as React from "react";
import { useEffect } from "react";
import { FormattedMessage } from "react-intl";
import { ImageCrop, ImageCropProps } from "./imageCrop/imageCrop";
import { DisableOverlay, ImageUploadStyle, UploadedImg } from "./imageUploadStyle";

const MAX_UPLOAD_SIZE = 2000000; // 2MB. Is set in Bytes

export type ImageType = null | string | undefined;
interface ImageUploadProps {
    disabled?: boolean;
    name: NamePath;
    required?: boolean;
    form: FormInstance;
    /* Default image url can be set here */
    image: ImageType;
    cropSettings?: ImageCropProps;
    noRemove?: boolean;
    /* Fires on field change - sends update url */
    onChange?(url: ImageType): void;
}

interface ImageUploadState {
    loading?: boolean;
    url?: ImageType;
    error?: string;
    blob?: string;
    changed: boolean;
}

export const ImageUpload: React.FC<ImageUploadProps> = (
    {
        name,
        cropSettings,
        form: formInstance,
        image,
        required,
        onChange,
        noRemove,
        disabled
    }
) => {
    const [stateImage, setStateImage] = React.useState<ImageUploadState>({
        loading: false,
        url: image,
        error: "",
        blob: "",
        changed: false
    });
    const [showCropper, setCropper] = React.useState(false);
    const [form] = useForm(formInstance);
    React.useEffect(() => {
        if (stateImage.changed) {
            if (onChange) {
                onChange(stateImage.url);
            }
            if (form) {
                form.setFields([{
                    name,
                    value: stateImage.url
                }]);
            }
        }
    }, [stateImage.url]);

    useEffect(() => {
        if (stateImage.url !== image) {
            setStateImage(pstate => ({ ...pstate, changed: true, url: image }));
        }
    }, [image]);

    const onClick = (e: any) => {
        e.preventDefault();
        setStateImage({
            blob: "",
            error: "",
            url: "",
            changed: true
        });
        setCropper(false);
    };

    const uploadImage = async ({ file }) => {
        try {
            if (file.size > MAX_UPLOAD_SIZE) {
                setStateImage({
                    ...stateImage,
                    error: "upload.maxFileExceeded"
                });
                return;
            }
            setCropper(false);
            setStateImage({
                ...stateImage,
                loading: true
            });

            const data = await toBase64(file);

            if (cropSettings && !environment.disableImageCropping) {
                setStateImage({
                    ...stateImage,
                    loading: false,
                    blob: data,
                    changed: true
                });

                setCropper(true);
            } else if (data) {
                const response = await cloudinaryUpload(data);
                if (response && response.url) {
                    setStateImage({
                        ...stateImage,
                        loading: false,
                        url: response.url,
                        blob: data,
                        changed: true
                    });
                }
            }
        } catch (error) {
            setStateImage({
                ...stateImage,
                error: "upload.tryAgain"
            });
        }
    };

    const onCompleteCrop = async (img) => {
        if (!img) {
            return;
        }
        setCropper(false);

        try {
            setStateImage({
                ...stateImage,
                loading: true
            });
            const response = await cloudinaryUpload(img);
            if (response && response.url) {
                setStateImage({
                    blob: img,
                    url: response.url,
                    loading: false,
                    changed: true
                });
            }
        } catch (error) {
            setStateImage({
                ...stateImage,
                loading: false,
                error: "upload.tryAgain"
            });
        }
    };

    return (
        <ImageUploadStyle>
            {disabled && <DisableOverlay />}
            <Upload.Dragger
                accept="image/*"
                customRequest={uploadImage}
            >
                {stateImage.loading ? <Spin /> : (<p className="ant-upload-drag-icon"><InboxOutlined /></p>)}
                <p className={required ? "ant-upload-text isRequired" : "ant-upload-text"}>
                    <FormattedMessage id="draganddrop.dragtoupload" />
                </p>
                {stateImage.error && <p className="ant-upload-text"><FormattedMessage id={stateImage.error} /></p>}
                {(stateImage.url && (
                    <UploadedImg
                        alt="Uploaded by user"
                        height={100}
                        src={stateImage.url}
                    />
                ))}
            </Upload.Dragger>
            {!noRemove && stateImage.url && (<Button onClick={onClick}><FormattedMessage id="draganddrop.remove" /></Button>)}
            {!environment.disableImageCropping && showCropper && cropSettings && (
                <ImageCrop
                    aspect={cropSettings.aspect}
                    rotate={cropSettings.rotate}
                    src={stateImage.blob}
                    zoom={cropSettings.zoom}
                    onImageCrop={onCompleteCrop}
                />
            )}
        </ImageUploadStyle>
    );
};
