import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { Flex } from "@components/flex/flex";
import { TableComponentProps, TableData, TableHandlers } from "@components/table/tableContainer";
import { TableIcon } from "@components/table/tableStyle";
import { Popconfirm, Table } from "antd";
import { ColumnProps } from "antd/lib/table";
import Column from "antd/lib/table/Column";
import * as React from "react";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";

export interface TagsTableData extends TableData {
    title: string;
    color: string;
}

export interface EventTagsTableData extends TagsTableData {
    projectId?: string | null;
}
export interface TagsTableProps extends TableComponentProps {
    dataSource: TagsTableData[];
    handlers: TableHandlers & {
        removeTag(id: string): void;
        buildUrl(id: string): string;
    };
    extraComponents: {
        exportContainer: React.ComponentType<{ id: string; }>;
    };
}

interface Handlers {
    children: JSX.Element;
    props: ColumnProps<TagsTableData>;
}

export const TagsTable: React.FC<TagsTableProps> = ({ handlers, extraComponents, ...tableProps }) => {
    const renderHandlers = (row: TagsTableData): Handlers => ({
        children: (
            <Flex
                alignItems="center"
                justifyContent="flex-end"
            >
                <extraComponents.exportContainer id={row.id} />
                <Link to={handlers.buildUrl(row.id)}>
                    <TableIcon as={EditOutlined} />
                </Link>
                <Popconfirm
                    cancelText="No"
                    okText="Yes"
                    title={<FormattedMessage id="deleteConfirm" />}
                    onConfirm={() => handlers.removeTag(row.id)}
                >
                    <TableIcon as={DeleteOutlined} />
                </Popconfirm>
            </Flex>
        ),
        props: {
            colSpan: 2
        }
    });

    const renderTitle = (text: string, row: any) => (
        <Link
            className="plainText"
            to={handlers.buildUrl(row.id)}
        >
            {text}
        </Link>
    );

    return (
        <Table
            pagination={{ showSizeChanger: true, showQuickJumper: true }}
            rowKey="id"
            {...tableProps}
        >
            <Column
                key="title"
                dataIndex="title"
                render={renderTitle}
                sorter={(a: TagsTableData, b) => a.title.localeCompare(b.title)}
                title={<FormattedMessage id="title" />}
            />
            <Column
                key="color"
                dataIndex="color"
                sorter={(a: TagsTableData, b) => a.color.localeCompare(b.color)}
                title={<FormattedMessage id="color" />}
            />
            <Column
                key="handlers"
                dataIndex=""
                render={(_, row: TagsTableData) => renderHandlers(row)}
                title=" "
                width={120}
            />
        </Table>
    );
};
