
import type {
    DOMConversionMap,
    DOMConversionOutput,
    DOMExportOutput,
    EditorConfig,
    LexicalEditor,
    LexicalNode,
    NodeKey,
    SerializedEditor,
    SerializedLexicalNode,
    Spread
} from "lexical";

import { $applyNodeReplacement, createEditor, DecoratorNode } from "lexical";
import * as React from "react";
import { Suspense } from "react";

const ImageComponent = React.lazy(() => import("./ImageComponent"));

export interface ImagePayload {
  altText: string;
  caption?: LexicalEditor;
  height?: number;
  key?: NodeKey;
  maxWidth?: number;
  showCaption?: boolean;
  src: string;
  width?: number;
  captionsEnabled?: boolean;
}

function isGoogleDocCheckboxImg(img: HTMLImageElement): boolean {
    return (
        img.parentElement != null
    && img.parentElement.tagName === "LI"
    && img.previousSibling === null
    && img.getAttribute("aria-roledescription") === "checkbox"
    );
}

function $convertImageElement(domNode: Node): null | DOMConversionOutput {
    const img = domNode as HTMLImageElement;
    if (img.src.startsWith("file:///") || isGoogleDocCheckboxImg(img)) {
        return null;
    }
    const { alt: altText, src, width, height } = img;
    const node = $createImageNode({ altText, height, src, width });
    return { node };
}

export type SerializedImageNode = Spread<
  {
    altText: string;
    caption: SerializedEditor;
    height?: number;
    maxWidth: number;
    showCaption: boolean;
    src: string;
    width?: number;
  },
  SerializedLexicalNode
>;

export class ImageNode extends DecoratorNode<JSX.Element> {
  public __src: string;

  public __altText: string;

  public __width: "inherit" | number;

  public __height: "inherit" | number;

  public __maxWidth: number;

  public __showCaption: boolean;

  public __caption: LexicalEditor;

  // Captions cannot yet be used within editor cells
  public __captionsEnabled: boolean;

  public static getType(): string {
      return "image";
  }

  public static clone(node: ImageNode): ImageNode {
      return new ImageNode(
          node.__src,
          node.__altText,
          node.__maxWidth,
          node.__width,
          node.__height,
          node.__showCaption,
          node.__caption,
          node.__captionsEnabled,
          node.__key
      );
  }

  public static importJSON(serializedNode: SerializedImageNode): ImageNode {
      const { altText, height, width, maxWidth, caption, src, showCaption } = serializedNode;
      const node = $createImageNode({
          altText,
          height,
          maxWidth,
          showCaption,
          src,
          width
      });
      const nestedEditor = node.__caption;
      const editorState = nestedEditor.parseEditorState(caption.editorState);
      if (!editorState.isEmpty()) {
          nestedEditor.setEditorState(editorState);
      }
      return node;
  }

  public exportDOM(): DOMExportOutput {
      const element = document.createElement("img");
      element.setAttribute("src", this.__src);
      element.setAttribute("alt", this.__altText);
      element.setAttribute("width", this.__width.toString());
      element.setAttribute("height", this.__height.toString());
      return { element };
  }

  public static importDOM(): DOMConversionMap | null {
      return {
          img: () => ({
              conversion: $convertImageElement,
              priority: 0
          })
      };
  }

  public constructor(
      src: string,
      altText: string,
      maxWidth: number,
      width?: "inherit" | number,
      height?: "inherit" | number,
      showCaption?: boolean,
      caption?: LexicalEditor,
      captionsEnabled?: boolean,
      key?: NodeKey
  ) {
      super(key);
      this.__src = src;
      this.__altText = altText;
      this.__maxWidth = maxWidth;
      this.__width = width || "inherit";
      this.__height = height || "inherit";
      this.__showCaption = showCaption || false;
      this.__caption = caption
      || createEditor({
          nodes: []
      });
      this.__captionsEnabled = captionsEnabled || captionsEnabled === undefined;
  }

  public exportJSON(): SerializedImageNode {
      return {
          altText: this.getAltText(),
          caption: this.__caption.toJSON(),
          height: this.__height === "inherit" ? 0 : this.__height,
          maxWidth: this.__maxWidth,
          showCaption: this.__showCaption,
          src: this.getSrc(),
          type: "image",
          version: 1,
          width: this.__width === "inherit" ? 0 : this.__width
      };
  }

  public setWidthAndHeight(
      width: "inherit" | number,
      height: "inherit" | number
  ): void {
      const writable = this.getWritable();
      writable.__width = width;
      writable.__height = height;
  }

  public setShowCaption(showCaption: boolean): void {
      const writable = this.getWritable();
      writable.__showCaption = showCaption;
  }

  // View

  public createDOM(config: EditorConfig): HTMLElement {
      const span = document.createElement("span");
      const { theme } = config;
      const className = theme.image;
      if (className !== undefined) {
          span.className = className;
      }
      return span;
  }

  public updateDOM(): false {
      return false;
  }

  public getSrc(): string {
      return this.__src;
  }

  public getAltText(): string {
      return this.__altText;
  }

  public decorate(): JSX.Element {
      return (
          <Suspense fallback={null}>
              <ImageComponent
                  resizable
                  altText={this.__altText}
                  height={this.__height}
                  maxWidth={this.__maxWidth}
                  nodeKey={this.getKey()}
                  src={this.__src}
                  width={this.__width}
              />
          </Suspense>
      );
  }
}

export function $createImageNode({
    altText,
    height,
    maxWidth = 500,
    captionsEnabled,
    src,
    width,
    showCaption,
    caption,
    key
}: ImagePayload): ImageNode {
    return $applyNodeReplacement(
        new ImageNode(
            src,
            altText,
            maxWidth,
            width,
            height,
            showCaption,
            caption,
            captionsEnabled,
            key
        )
    );
}

export function $isImageNode(
    node: LexicalNode | null | undefined
): node is ImageNode {
    return node instanceof ImageNode;
}
