/* eslint-disable react/jsx-sort-props */
import { ContentHeader } from "@components/contentHeader/contentHeader";
import { EditorField } from "@components/field/editorField";
import { Field } from "@components/field/field";
import { TranslationTabs } from "@components/translations/translationTabs/translationTabs";
import { AmbassadorsFormValues } from "@pages/addAmbassadorsPage/addAmbassadorsPage";
import { languages } from "@pages/translationsPage/constants/languages";
import { LanguageErrors } from "@utils/errorsToLanguageErrors";
import { AmbassadorLanguageFieldProps } from "@utils/languageFieldProps";
import { maxCharacters } from "@utils/maxCharacters";
import { LanguageObject } from "@utils/toLanguageObject";
import { Card } from "antd";
import { FormInstance } from "antd/lib/form";
import * as React from "react";
import { FormattedMessage } from "react-intl";

export interface ContentFormValues {
    title: LanguageObject;
    info: LanguageObject;
    description: LanguageObject;
}

export interface ContentCardProps {
    form?: FormInstance;
    defaultValues: AmbassadorsFormValues;
    hidden?: boolean;
    disabled?: boolean;
    activeLanguage: string;
    languageErrors?: LanguageErrors;
    onBlur(): void;
    // onSubmit(e: React.FormEvent<HTMLFormElement> | React.MouseEvent<HTMLButtonElement>): void;
    changeSelectedLanguage(languageCode: string): void;
}

const titleField = (props: AmbassadorLanguageFieldProps) => (
    <Field
        key={`title.${props.language}`}
        hidden={props.hidden}
        id={`title.${props.language}`}
        info={maxCharacters()}
        label={<FormattedMessage id="title" />}
        name={["title", props.language]}
        rules={[
            {
                max: 140,
                message: <FormattedMessage id="form.toolong" />
            },
            {
                required: true,
                message: <FormattedMessage id="form.isrequired" />
            }
        ]}
        {...props.sharedProps}
    />
);

const infoField = (props: AmbassadorLanguageFieldProps) => (
    <Field
        key={`info.${props.language}`}
        hidden={props.hidden}
        id={`info.${props.language}`}
        info={maxCharacters()}
        label={<FormattedMessage id="info" />}
        name={["info", props.language]}
        rules={[{
            max: 140,
            message: <FormattedMessage id="form.toolong" />
        }]}
        {...props.sharedProps}
    />
);

const descriptionField = (props: AmbassadorLanguageFieldProps) => {
    return (
        <EditorField
            key={`description.${props.language}`}
            disabled={props.sharedProps.disabled}
            form={props.sharedProps.form}
            hidden={props.hidden}
            id={`description.${props.language}`}
            value={props.defaultValues.description && props.defaultValues.description[props.language]}
            onChangeUnsaved={props.sharedProps.onChange}
            label={<FormattedMessage id="description" />}
            name={["description", props.language]}
            isAdvancedMode={false}
        />
    );
};

export class ContentCard extends React.Component<ContentCardProps> {
    public shouldComponentUpdate(props) {
        return !(this.props.hidden && props.hidden);
    }

    public render() {
        const { changeSelectedLanguage, hidden, languageErrors, disabled, activeLanguage, form, defaultValues, onBlur } = this.props;
        const sharedProps = {
            disabled,
            form,
            onBlur
        };

        const langFieldProps = languages.map(language => ({ sharedProps, defaultValues, language: language.code, hidden: language.code !== activeLanguage }));

        return (
            <Card hidden={hidden}>
                <ContentHeader
                    title={<FormattedMessage id="content" />}
                />
                <TranslationTabs
                    activeLanguage={activeLanguage}
                    errors={languageErrors}
                    handleChangeSelectedLanguage={changeSelectedLanguage}
                    languages={languages}
                />
                {langFieldProps.map(titleField)}
                {langFieldProps.map(infoField)}
                {langFieldProps.map(descriptionField)}
            </Card>
        );
    }
}
