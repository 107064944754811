
import type {
    DOMConversionMap,
    DOMConversionOutput,
    DOMExportOutput,
    EditorConfig,
    ElementFormatType,
    LexicalEditor,
    LexicalNode,
    NodeKey,
    Spread
} from "lexical";

import { BlockWithAlignableContents } from "@lexical/react/LexicalBlockWithAlignableContents";
import {
    DecoratorBlockNode,
    SerializedDecoratorBlockNode
} from "@lexical/react/LexicalDecoratorBlockNode";
import * as React from "react";

type YouTubeComponentProps = Readonly<{
  className: Readonly<{
    base: string;
    focus: string;
  }>;
  format: ElementFormatType | null;
  nodeKey: NodeKey;
  videoID: string;
}>;

function YouTubeComponent({
    className,
    format,
    nodeKey,
    videoID
}: YouTubeComponentProps) {
    return (
        <BlockWithAlignableContents
            className={className}
            format={format}
            nodeKey={nodeKey}
        >
            <iframe
                allowFullScreen
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                frameBorder="0"
                height="315"
                src={`https://www.youtube-nocookie.com/embed/${videoID}`}
                title="YouTube video"
                width="560"
            />
        </BlockWithAlignableContents>
    );
}

export type SerializedYouTubeNode = Spread<
  {
    videoID: string;
  },
  SerializedDecoratorBlockNode
>;

function $convertYoutubeElement(
    domNode: HTMLElement
): null | DOMConversionOutput {
    const videoID = domNode.getAttribute("data-lexical-youtube");
    if (videoID) {
        const node = $createYouTubeNode(videoID);
        return { node };
    }
    return null;
}

export class YouTubeNode extends DecoratorBlockNode {
  public __id: string;

  public static getType(): string {
      return "youtube";
  }

  public static clone(node: YouTubeNode): YouTubeNode {
      return new YouTubeNode(node.__id, node.__format, node.__key);
  }

  public static importJSON(serializedNode: SerializedYouTubeNode): YouTubeNode {
      const node = $createYouTubeNode(serializedNode.videoID);
      node.setFormat(serializedNode.format);
      return node;
  }

  public exportJSON(): SerializedYouTubeNode {
      return {
          ...super.exportJSON(),
          type: "youtube",
          version: 1,
          videoID: this.__id
      };
  }

  public constructor(id: string, format?: ElementFormatType, key?: NodeKey) {
      super(format, key);
      this.__id = id;
  }

  public exportDOM(): DOMExportOutput {
      const element = document.createElement("iframe");
      element.setAttribute("data-lexical-youtube", this.__id);
      element.setAttribute("width", "560");
      element.setAttribute("height", "315");
      element.setAttribute(
          "src",
          `https://www.youtube-nocookie.com/embed/${this.__id}`
      );
      element.setAttribute("frameborder", "0");
      element.setAttribute(
          "allow",
          "accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      );
      element.setAttribute("allowfullscreen", "true");
      element.setAttribute("title", "YouTube video");
      return { element };
  }

  public static importDOM(): DOMConversionMap | null {
      return {
          iframe: (domNode: HTMLElement) => {
              if (!domNode.hasAttribute("data-lexical-youtube")) {
                  return null;
              }
              return {
                  conversion: $convertYoutubeElement,
                  priority: 1
              };
          }
      };
  }

  public updateDOM(): false {
      return false;
  }

  public getId(): string {
      return this.__id;
  }

  public getTextContent(): string {
      return `https://www.youtube.com/watch?v=${this.__id}`;
  }

  public decorate(_editor: LexicalEditor, config: EditorConfig): JSX.Element {
      const embedBlockTheme = config.theme.embedBlock || {};
      const className = {
          base: embedBlockTheme.base || "",
          focus: embedBlockTheme.focus || ""
      };
      return (
          <YouTubeComponent
              className={className}
              format={this.__format}
              nodeKey={this.getKey()}
              videoID={this.__id}
          />
      );
  }
}

export function $createYouTubeNode(videoID: string): YouTubeNode {
    return new YouTubeNode(videoID);
}

export function $isYouTubeNode(
    node: YouTubeNode | LexicalNode | null | undefined
): node is YouTubeNode {
    return node instanceof YouTubeNode;
}
