
import "./index.css";

import { DraggableBlockPlugin_EXPERIMENTAL } from "@lexical/react/LexicalDraggableBlockPlugin";
import React, { useRef } from "react";

const DRAGGABLE_BLOCK_MENU_CLASSNAME = "draggable-block-menu";

function isOnMenu(element: HTMLElement): boolean {
    return Boolean(element.closest(`.${DRAGGABLE_BLOCK_MENU_CLASSNAME}`));
}

export default function DraggableBlockPlugin({
    anchorElem = document.body
}: {
  anchorElem?: HTMLElement;
}): JSX.Element {
    const menuRef = useRef<HTMLDivElement>(null);
    const targetLineRef = useRef<HTMLDivElement>(null);

    return (
        // eslint-disable-next-line react/jsx-pascal-case
        <DraggableBlockPlugin_EXPERIMENTAL
            anchorElem={anchorElem}
            isOnMenu={isOnMenu}
            menuComponent={(
                <div
                    ref={menuRef}
                    className="icon draggable-block-menu"
                >
                    <div className="icon" />
                </div>
            )}
            menuRef={menuRef}
            targetLineComponent={(
                <div
                    ref={targetLineRef}
                    className="draggable-block-target-line"
                />
            )}
            targetLineRef={targetLineRef}
        />
    );
}
