/* eslint-disable jsx-a11y/label-has-associated-control */
import Editor from "@components/WYSIWYGEditor/Editor";
import HTMLRawEditor from "@components/WYSIWYGEditor/HTMLRawEditor";
import { ExtendedListItemNode } from "@components/WYSIWYGEditor/nodes/ExtendedListNode";
import { ExtendedParagraphNode } from "@components/WYSIWYGEditor/nodes/ExtendedParagraphNode";
import { ExtendedQuoteNode } from "@components/WYSIWYGEditor/nodes/ExtendedQuoteNode";
import { ExtendedTextNode } from "@components/WYSIWYGEditor/nodes/ExtendedTextNode";
import PlaygroundNodes from "@components/WYSIWYGEditor/nodes/PlaygroundNodes";
import PlaygroundEditorTheme from "@components/WYSIWYGEditor/themes/PlaygroundEditorTheme";
import { ListItemNode } from "@lexical/list";
import { LexicalComposer } from "@lexical/react/LexicalComposer";
import { QuoteNode } from "@lexical/rich-text";
import { Form, Input } from "antd";
import { FormInstance, FormItemProps, RuleObject } from "antd/lib/form";
import { ParagraphNode, TextNode } from "lexical";
import * as React from "react";
import styled from "styled-components";
import "../WYSIWYGEditor/index.css";
import { FieldInfo } from "./fieldStyle";

export interface EditorFieldProps extends FormItemProps {
    form?: FormInstance;
    formValues?: any;
    label: JSX.Element;
    info?: JSX.Element;
    disabled?: boolean;
    id?: string;
    value?: string;
    hidden?: boolean;
    hasError?: any;
    onChange?(value: string): void;
    onChangeUnsaved?(): void;
    onBlur?(): void;
    isAdvancedMode: boolean;
}

export interface EditorFieldState {
    value: string | null;
}

const initialConfig = {
    editorState: null,
    namespace: "Playground",
    nodes: [
        ...PlaygroundNodes,
        {
            replace: TextNode,
            with: (node: TextNode) => new ExtendedTextNode(node.__text)
        },
        {
            replace: QuoteNode,
            with: () => new ExtendedQuoteNode()
        },
        {
            replace: ParagraphNode,
            with: () => {
                return new ExtendedParagraphNode();
            }
        },
        {
            replace: ListItemNode,
            with: () => {
                return new ExtendedListItemNode();
            }
        }
    ],
    onError: (error: Error) => {
        throw error;
    },
    theme: PlaygroundEditorTheme
};

export const EditorField = (props: EditorFieldProps): JSX.Element => {
    const { onBlur, hasError, ...inputProps } = props;

    const handleValueChange = (value: string): void => {
        const { onChangeUnsaved } = props;

        if (inputProps.form && inputProps.name) {
            inputProps.form.setFields([
                {
                    name: inputProps.name,
                    value
                }
            ]);
        }

        if (onChangeUnsaved) {
            onChangeUnsaved();
        }
    };

    const isRequired = inputProps.required || (inputProps.rules || []).find((rule: RuleObject) => rule.required === true);
    const { value, disabled } = inputProps;
    const initialValue = value ?? "";

    return (
        <div className="editor-wrapper">
            {!inputProps.hidden && (
                <label
                    className={isRequired ? "isRequired" : ""}
                    htmlFor={inputProps.id}
                >
                    {inputProps.label}
                </label>
            )}
            {!inputProps.hidden && inputProps.info && <FieldInfo>{inputProps.info}</FieldInfo>}
            <EditorWrapper
                className={hasError ? "manual-error-styling" : ""}
                hidden={inputProps.hidden}
            >
                {inputProps.disabled && <DisableOverlay />}
                {props.isAdvancedMode ? (
                    <HTMLRawEditor
                        disabled={disabled}
                        initialValue={initialValue}
                        onBlur={onBlur}
                        onChange={handleValueChange}
                    />
                ) : (
                    <LexicalComposer initialConfig={initialConfig}>
                        <div className="editor-shell">
                            <Editor
                                disabled={disabled}
                                initialValue={initialValue}
                                onBlur={onBlur}
                                onChange={handleValueChange}
                            />
                        </div>
                    </LexicalComposer>
                )}
            </EditorWrapper>
            <div className="manual-error-text">
                {!inputProps.hidden && hasError?.errors}
            </div>
            <Form.Item
                hidden
                name={inputProps.name}
                rules={!inputProps.disabled ? inputProps.rules : undefined}
            >
                <Input />
            </Form.Item>
        </div>
    );
};

const EditorWrapper = styled.div`
    position: relative;
    border: 1px solid #d9d9d9;
    border-radius: 4px;
`;

const DisableOverlay = styled.div`
    width: 100%;
    height: 100%;
    position: absolute;
    cursor: not-allowed;
    z-index: 1;
    background-color: rgba(0, 0, 0, 0.05);
`;
